<template lang="" enter-class="common-for-all">
  <div>
  <div v-if="view =='vehicleDetails'" class="select-RTO">
    <div class="commoncard">
      <div class="searchinput">
          <input
          class="form-control mr-sm-2"
          type="search"
          style="padding:10px"
          :placeholder="motorProductType ==='4W-Pvt' ? carplaceholder: bikeplaceholder "
          aria-label="Search"
          v-model="searchData"
          ref="searchInput"
          @input="filterData()"
        />
        <img  src="../../assets/images/search.svg" v-if="!searchData"/>
        <span v-if="searchData" @click="clearSearchTerm()" tabindex='10'>&times;</span>
        </div>
      <small
        id="emailHelp"
        class="form-text text-muted d-flex gap-1 align-items-center"
        > <img src="../../assets/images/info.svg"  class="backarrow cursor-pointer" />
        <!-- <label>Eg. {{motorProductType ==='4W-Pvt' ? carexample: bikeexample }}</label> -->
        <label>Eg. {{ this.showExample() }}</label>
      </small>
      <p class="mt-3 error-text" v-if="this.noSearchMatch && filteredResultData.length==0 && this.searchData!=''">No Record Found</p>
      <div v-if="filteredResultData.length > 0"></div>
      <div v-if="!(this.noSearchMatch && filteredResultData.length==0 && this.searchData!='')">
        <div v-if="filteredResultData.length > 0">
          <div>
            <div  v-for="(item, index) in filteredResultData" :key="index" class="selectState d-flex justify-content-between" :style="{'background-color': (typeof(item)=='String' && item === selectedItemvalue || deepEqual(JSON.parse(JSON.stringify(item)), JSON.parse(JSON.stringify(selectedItemvalue)))) ? '#3B39D9' : $store.state.theme == 'light' ? 'white' : 'transparent'}" @click="getNewData(item)">
              <p v-if="displayStepData=='state'" class="mb-0">{{ (item).toUpperCase()}}</p>
              <p v-if="displayStepData=='city'" class="mb-0">{{ (item).toUpperCase() }}</p>
              <p v-if="displayStepData=='rtoCode'" class="mb-0">{{ (item).toUpperCase() }}</p>
              <p v-if="displayStepData=='carMake'" class="mb-0">{{(item.makeDescription).toUpperCase() }}</p>
              <p v-if="displayStepData=='model'" class="mb-0">{{(item.modelDesc).toUpperCase() }}</p>
              <p v-if="displayStepData=='variant'" class="mb-0">{{(item.variantDesc).toUpperCase() }}</p>
              <p v-if="displayStepData=='fuelType'" class="mb-0">{{(item.fuelType).toUpperCase() }}</p>
              <div>
                
                <img src="../../assets/images/rightarrow.svg" class="commonicon"   />
              </div>
            </div>
          </div>
          </div>
        <div v-else>
          <div v-for="(item, index) in displayedData" :key="index" >
            <div class="selectState d-flex justify-content-between"  :class="{'highlightColor': (typeof(item)=='String' && item === selectedItemvalue || deepEqual(JSON.parse(JSON.stringify(item)), JSON.parse(JSON.stringify(selectedItemvalue))))}" @click="getNewData(item)" >
              <p v-if="displayStepData=='state'" class="mb-0">{{ (item).toUpperCase() }}</p>
              <p v-if="displayStepData=='city'" class="mb-0">{{ (item).toUpperCase()}}</p> 
              <p v-if="displayStepData=='rtoCode'" class="mb-0">{{ (item).toUpperCase() }}</p>
              <p v-if="displayStepData=='carMake'" class="mb-0">{{(item.makeDescription).toUpperCase() }}</p>
              <p v-if="displayStepData=='model'" class="mb-0">{{(item.modelDesc).toUpperCase() }}</p>
              <p v-if="displayStepData=='variant'" class="mb-0">{{(item.variantDesc).toUpperCase()}}</p>
              <p v-if="displayStepData=='fuelType'" class="mb-0">{{(item.fuelType).toUpperCase() }}</p>

              <div>
                <img src="../../assets/images/rightarrow.svg"  class="commonicon"    />
              </div>
            </div>
            
          </div>
        </div>
      </div>
    
    <div v-if="!filteredResultData.length>0 && displayedData.length>9 && !(this.noSearchMatch && filteredResultData.length==0 && this.searchData!='')">
    <div v-if="!showAllItems">
    <button @click="showAllItems = !showAllItems" class="btn btn-link nextpagerout">View All</button>
    </div>
    <div v-if="showAllItems">
    <button @click="showAllItems = !showAllItems" class="btn btn-link nextpagerout">Show Less</button>
    </div>
  </div>
    </div>
  </div>

  <!-- Showing Additional Details Page -->

  <div v-if="view=='additonalDetails'">
    <div class="additional-Details">
    <div class="commoncard">
      <p class="text-center">
        Few more <b>additional</b> details about your {{motorProductType ==='4W-Pvt' ? "Car": "Bike" }}
      </p>
      <div class="manage-selects mb-3">
        <div class=" ">
          
          <label class="headinglabel">Registration Date </label>
          <div class="position-relative">
            <input type="date" aria-label="Regd Year" id="dob" name="dob" :max="maxDate" :min="minDate" class="form-control"  v-model="selectedDate"  @blur="handleDateChange">
          <img src="/images/date.svg" class="downarrowimage">
          </div>
        </div>
        <div >
          <label class="headinglabel">Manufacturing Year </label>
          <div class="position-relative " >
            <select class="form-select" v-model="selectedYear" aria-label="Default select example">
                <option selected disabled value="0">Mfg Year</option>
                <option v-for="year in yearOptions"  :key="year" :value="year">{{ year }}</option>
                
            </select>
            <img src="/images/downarrow.svg" class="downarrowimage">
          </div>
            
        </div>
      </div>
      <p style="color:red;font-size:12px">
        {{dateError}}
      </p>
      
      <button class="btn commonButton " @click="getNewData('',selectedDate,selectedYear)" :disabled="!selectedDate" >Proceed</button>
    </div>
  </div>
  </div>

<!-- Showing Claim  -->
 <div v-if="view=='claim' && quoteCategory=='Roll-Over'">
  <div class="view-Claim">
    <div class="commoncard">
      <p>Previous Policy Details <img src="../../assets/images/info.svg" class="commonicon"     /></p>

      <form>
    <div v-if="showOdSection">
      <label class="headinglabel"  v-if=!showPreviousExpiry>Own Damage Expiry Date</label>
      <label class="headinglabel" v-if=showPreviousExpiry>Previous Expiry Date</label>

      <div  class="position-relative">
        <input 
        type="date" 
        class="form-control mb-3" 
        :placeholder="showPreviousExpiry ? 'Previous Expiry Date' : 'Own Damage Expiry Date'" v-model="odDate" :max="maxOdTpDate"   @input="validateOdDate">
        <img src="/images/date.svg" class="downarrowimage">
      </div>
        <p style="color:red;font-size:12px">{{oddateError}}</p>
    </div>
    <div  v-if="showTpSection">
      <label class="headinglabel">Third Party Expiry Date</label>
      <div  class="position-relative">
        <input type="date" class="form-control mb-3" placeholder="Third Party Expiry Date" v-model="tpDate" >
     <img src="/images/date.svg" class="downarrowimage">
      </div>
    </div>
    <hr/>
      <p class="mb-1">Confirm No Claim Bonus (NCB)<img src="../../assets/images/info.svg" class="commonicon"    /></p>
      <p class="subheading">Did you make a claim in your existing policy?</p>
      <div class="form-check form-switch d-flex gap-2 align-item-center">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked"
          v-model="isChecked"
          
        />
        <label class="form-check-label headinglabel" for="flexSwitchCheckChecked" style="margin-top:0.5rem">{{ isChecked ? 'Yes' : 'No' }}</label>
      </div>
      <div class=" position-relative">
        
      <div v-if="!isChecked" >
        <select class="form-select mt-3" v-model="applicablePercentage" aria-label="Default select example">
    <option disabled value="">Select NCB Percentage</option>
    <option v-if="isDateMoreThan90Days(odDate)" :key="index" :value="percentOptions[0]">{{ percentOptions[0] }}</option>
    <option v-if="!isDateMoreThan90Days(odDate)" v-for="(item, index) in percentOptions" :key="index" :value="item">{{ item }}</option>
  </select>
  <img src="/images/downarrow.svg" class="downarrowimage">
        </div>
      </div>
      <br />
      <div v-if="!isChecked && this.applicablePercentage !=''" class="rounddiv mb-2"><p>{{this.applicablePercentage}} NCB Percentage in previous Year</p></div>
      <div v-if="isChecked" class="rounddiv mb-2"><p class="text-align-left">NCB is not applicable</p></div>
        <!-- <router-link  :to="odDate && tpDate ? '/policy-quote' : ''"> -->
          <p style="color:red;font-size:12px" v-if="this.quoteCategory=='Roll-Over'">Entering incorrect value of NCB percentage and Claim in previous policy can lead to rejection of claim in future.</p>
          <button class="btn commonButton " @click="addOdTpDate()" :disabled="!odDate || !tpDate || (!isChecked && this.applicablePercentage =='' ) " v-if="!showPreviousExpiry">View Quotes</button>
          
        <!-- </router-link> -->
        <!-- <router-link  :to="odDate ? '/policy-quote' : ''"> -->
        <button class="btn commonButton " @click="addOdTpDate()" :disabled="!odDate || (!isChecked && this.applicablePercentage =='' )" v-if="showPreviousExpiry">View Quotes </button>
      <!-- </router-link> -->
      </form>
    </div>
  </div>
</div>
<div v-else-if="view=='claim' && quoteCategory=='New'">
  <div class="view-Claim commoncard">
    <input
                type="text"
                class="form-control mt-3"
                id="mob-number"
                v-model.trim="newBikeUser.mobile"
                placeholder="Mobile Number"
                @keypress="isNumber($event)"
                maxlength="10"
                @input="getFieldValidation('mobile',$event)"
              
              />
               <p class="error-text" v-if="error.mobile" style="color: red;" >{{ error.mobile }}</p>
              <!--<input
                type="text"
                class="form-control mt-3"
                id="pin-code"
                v-model.trim="newBikeUser.pincode"
                placeholder="Pin Code"
                pattern="[0-9]{6}"              
                :maxlength="6"
                @input="getFieldValidation('pinCode',$event)"
                @keypress="isNumber($event)"


              />
              <p class="error-text" v-if="error.pinCode" style="color: red;">{{ error.pinCode }}</p> -->
              <button class="btn commonButton mt-3" :class="{disabledButton: checkformData()}" :disabled="incorrectData" @click="ViewQuoteNewVehicle()">View Quotes</button>
  </div>
</div>
</div>
</template>
<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: "VehicleDetails",
  props: {
    carplaceholder: {
      type: String,
      required: true,
    },
    bikeplaceholder: {
      type: String,
      required: true,
    },
    carexample: {
      type: String,
      required: true,
    },
    bikeexample: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    displayStepData: {
      type: String,
      required: false,
    },
    showOdSection: {
      type: Boolean,
      required: false,
    },
    showTpSection: {
      type: Boolean,
      required: false,
    },
    showPreviousExpiry: {
      type: Boolean,
      required: false,
    },
    percentOptions:{
      type: Object,
      required: false,
    },
    selectedItemvalue:{
      type:String
    }
  },
  setup() {
    const searchInput = ref(null);

    const focusSearchInput = () => {
      if (searchInput.value) {
        searchInput.value.focus();
      }
    };

    function focusTheInput(){
       searchInput.value.focus();
    }

    const handleKeyPress = () => {
      focusSearchInput();
    };

    onMounted(() => {
      focusSearchInput();
      window.addEventListener('keypress', handleKeyPress);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('keypress', handleKeyPress);
    });

    return {
      searchInput,
      focusTheInput
    };
  },
  data() {
    return {
      applicablePercentage:"",
      isChecked: false, 
      displayNewStepData: null,
      userData: {},
      searchData: "",
      resultData: [],
      showAllItems: false,
      newItem:[],
      filteredResultData:[],
      selectedDate: '', 
      selectedYear: 0, 
      yearOptions: [],
      motorProductType:'',
      odDate:'',
      tpDate:'',
      quoteCategory:'',
      dateError:"",
      oddateError:"",
      newBikeUser:{
        mobile:'',
        pincode:''
      },
      error:[],
      incorrectData: true,
      noSearchMatch: false,
      isBHSeries: false
    };
  },
  mounted() {
   
    if(this.view==='claim'){
this.oddateError="";
    }
    this.quoteCategory=this.$store.state.quoteCategory;
    if(sessionStorage.getItem("userData")){
        const parsedData=JSON.parse(sessionStorage.getItem("userData"));
        if (parsedData.OdDate!=="") {
          this.odDate=parsedData.OdDate;
          this.tpDate=parsedData.TpDate;
          this.selectedDate=parsedData.RegDate;
          if (this.selectedDate) {
          const selectedYear = new Date(this.selectedDate).getFullYear();
          this.yearOptions = [selectedYear, selectedYear - 1];
          this.selectedYear=selectedYear;
          
        }
          this.isChecked=parsedData.previous_year_claim;
          // this.percentOptions=parsedData.percentOptions;
          // this.handleDateChange();
        }
      }
    this.motorProductType = this.$store.state.motorProductType;
    this.newItem=this.data
    if (sessionStorage.getItem("userData")) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
      this.setBHSeriesDate();
    }
  },
  updated() {
    this.newItem=this.data
  },
  
  methods: {
    checkBHSeries() {
      const fullCurrentYear = new Date().getFullYear();
      // const bhSeriesPattern = /^(\d{2})BH(\d{4})([A-Z]{2})$/;
      const bhSeriesPattern = /^(\d{2})([A-Z]{2})(\d{4})([A-Z]{1,2})$/;
      if(this.userData.vehicleNumber){
        const match = this.userData.vehicleNumber.match(bhSeriesPattern);
        if (!match) {
          return { valid: false, regYear: null };
        }
        const regYearLastTwo = parseInt(match[1], 10);
        const regYearFull = 2000 + regYearLastTwo;
        if (regYearFull < 2021 || regYearFull > fullCurrentYear) {
          return { valid: false, regYear: regYearFull };
        }
        return { valid: true, regYear: regYearFull };
      }
    },
    setBHSeriesDate(){
      if(this.userData.vehicleNumber){
        this.userData = JSON.parse(sessionStorage.getItem("userData"));
        
        if(this.checkBHSeries().valid){
          this.userData["RegDate"]=`${this.checkBHSeries().regYear}-01-01`;
          this.userData["MfgYear"]=this.checkBHSeries().regYear;
          sessionStorage.setItem("userData",JSON.stringify(this.userData));
          this.selectedDate = this.userData["RegDate"];
          this.selectedYear=this.userData["MfgYear"];
        }else{
          return;
        }
      }
    },
    isDateMoreThan90Days(odDate) {
      const date = new Date(odDate);
      const currentDate = new Date();
      const differenceInMilliseconds = currentDate - date;
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      return differenceInDays > 90;
  },
    clearSearchTerm(){
      this.searchData = "";
      this.focusTheInput();
      this.filteredResultData.length = 0;
    },
    showExample(){
    if(this.displayStepData=='carMake'){
      return this.displayedData[0]?.makeDescription.toUpperCase();
    }
    else if(this.displayStepData=='model'){
      return this.displayedData[0]?.modelDesc.toUpperCase();
    }
    else if(this.displayStepData=='variant'){
      return this.displayedData[0]?.variantDesc.toUpperCase();
    }
    else if(this.displayStepData=='fuelType'){
      return this.displayedData[0]?.fuelType.toUpperCase();
    }
    else{
      return this.displayedData[0]?.toUpperCase();
    }
    },
    maxOdTpDate() {
      const today = new Date();
      const maxDate = new Date(today);
      maxDate.setDate(today.getDate() + 45);
      return maxDate.toISOString().split('T')[0]; // Formats the date as YYYY-MM-DD
    },
    validateOdDate(event) {
      const inputDate = new Date(event.target.value);
      const maxDate = new Date(this.maxOdTpDate);
      if (inputDate > maxDate) {
        // If the input date is beyond the max date, reset the input
        event.target.value = this.maxOdTpDate;
        this.odDate = "";
        this.oddateError="Expiry Date can not be greater than 45 days in future"
      } else {
         this.oddateError=""
        this.odDate = event.target.value;
      }
    },
    calculateMaxDate() {
                    // Calculate today's date
                    let today = new Date();
                    let maxDate = new Date(today);
                    if(this.quoteCategory=="Roll-Over"){
                      maxDate.setMonth(maxDate.getMonth() - 10);
                      let yyyy = maxDate.getFullYear();
                      let mm = String(maxDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                      let dd = String(maxDate.getDate()).padStart(2, '0'); 
                     return `${yyyy}-${mm}-${dd}`;
                    }
                    if(this.quoteCategory=="New"){
        maxDate.setDate(maxDate.getDate() + 30);
        let yyyy = maxDate.getFullYear();
        let mm = String(maxDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        let dd = String(maxDate.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
      }
           
                },
                
      calculateMinDate(){
        if(this.quoteCategory=="New"){
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        let dd = String(today.getDate()).padStart(2, '0');
        console.log("--",`${yyyy}-${mm}-${dd}`)
        return `${yyyy}-${mm}-${dd}`;
      }
      else{
        return `${1950}-${11}-${11}`;
      }
      },
                handleDateChange(event) {
                  console.log("handeldatechange")
                    let inputDate = new Date(event.target.value);
                    let maxDate = new Date(this.calculateMaxDate());
                    let minDate=new Date(this.calculateMinDate());
                    
                    if(this.quoteCategory=="New"){
                      if(inputDate<minDate || inputDate > maxDate){
                        event.target.value = ""; 
                        this.selectedDate='';
                        this.yearOptions = "";
                        this.dateError="Registration Date can be between current date and upto 30 days in future"
                      }
                      else {
                        this.dateError="";
                        this.selectedDate = event.target.value; 
                        if (this.selectedDate) {
                         
                      const selectedYear = new Date(this.selectedDate).getFullYear();
                      this.yearOptions = [selectedYear, selectedYear - 1];
                      this.selectedYear = selectedYear; 
                      this.userData = JSON.parse(sessionStorage.getItem("userData"));
                      this.userData["RegDate"]=this.selectedDate;
                      // this.userData["MfgYear"]=this.selectedYear;
                      sessionStorage.setItem("userData",JSON.stringify(this.userData));
                  } 
                  else {
                      this.yearOptions = [];
                      this.selectedYear = '';
                      }
                    }
                    }
                 else{
                    if (inputDate > maxDate || inputDate<minDate) {
                        event.target.value = ""; 
                        this.selectedDate='';
                        this.yearOptions = "";
                        if(this.quoteCategory=="Roll-Over"){
                          this.dateError="Registration date cannot be less than 10 months in the past"
                        }
                          } 
                          else {
                            this.dateError="";
                        this.selectedDate = event.target.value; 
                        if (this.selectedDate) {
                      const selectedYear = new Date(this.selectedDate).getFullYear();
                      this.yearOptions = [selectedYear, selectedYear - 1];
                      this.selectedYear = selectedYear; 
                      this.userData = JSON.parse(sessionStorage.getItem("userData"));
                      this.userData["RegDate"]=this.selectedDate;
                      // this.userData["MfgYear"]=this.selectedYear;
                      sessionStorage.setItem("userData",JSON.stringify(this.userData));
                  } 
                  else {
                      this.yearOptions = [];
                      this.selectedYear = '';
                      }
                    }}
                },
               
    // handleDateChange() {
    //   if (this.selectedDate) {
    //     const selectedYear = new Date(this.selectedDate).getFullYear();
    //     this.yearOptions = [selectedYear, selectedYear - 1];
    //     this.selectedYear = selectedYear; // Default to the selected year
    //     this.userData = JSON.parse(sessionStorage.getItem("userData"));
    //     this.userData["RegDate"]=this.selectedDate;
    //     // this.userData["MfgYear"]=this.selectedYear;
    //   sessionStorage.setItem("userData",JSON.stringify(this.userData));
    //   } else {
    //     this.yearOptions = [];
    //     this.selectedYear = '';
    //   }
    // },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      var inputValue = evt.target.value;
      if ((inputValue === "0" && inputValue.length > 0) || (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getFieldValidation(name,event){
    
      if(name=="mobile")
      {
        const regex = /^[6789]\d{9}$/;
        if(regex.test(this.newBikeUser.mobile)){
                 this.error["mobile"]=""
        }
      else{ 
        this.error["mobile"] = "Please enter a valid mobile number";
      }
      }

      else if (name=="pinCode") {
          if(this.newBikeUser.pincode && event.target.value.length==6){
          // this.error["pinCode"] = "";
          // getPincode(this.userData.pinCode);
          this.getPincode(this.newBikeUser.pincode);
          }
        else {
          this.incorrectData = true;
          this.error["pinCode"] = "Please enter a valid Pincode";
        }
      }
    },
    checkformData(){
      console.log("inside checkformData")
       if (this.error["mobile"] == "" ){ 
         this.incorrectData = false;
        }
        else {
          this.incorrectData = true;
        }
   },
   getPincode(pincode){
    this.incorrectData = true;  
    let headers={};
          let reqData=[pincode];
          this.backendApi("getPincodeInfo", reqData, headers, "get")
        .then((response) => {
          if(response.data.errors.length >0) {
            this.incorrectData = true;  
            this.error["pinCode"] = "Please enter a valid Pincode";
          } else if(response && response.data) {
            this.incorrectData = false;
            this.error["pinCode"] = '';
          }
        })
        .catch((error) => {
          this.incorrectData = true;  
          console.log('error msg block dp', error);
          const msg = "Service is Unavailable, Please try again later.";
          this.$router.push({ path: '/error-page', query: { backUrl:'New-Journey', msg: msg } });
        });
   },
 
    //add session Data
    addOdTpDate(){
      
     
      this.userData= JSON.parse(sessionStorage.getItem("userData"));
        this.userData["OdDate"]=this.odDate;
        this.userData["TpDate"]=this.tpDate;
        this.userData["previous_year_claim"]=this.isChecked;
        this.userData["applicablePercentage"]=this.applicablePercentage;
         let isDateMoreThan90Days=this.isDateMoreThan90Days(this.odDate)
       if(isDateMoreThan90Days){
        let percentOptions90days=[];
        percentOptions90days.push(this.percentOptions[0]);
        this.userData["percentOptions"] = percentOptions90days;
       }
        else{
        this.userData["percentOptions"] = this.percentOptions
        }
        
        sessionStorage.setItem("userData",JSON.stringify(this.userData));
        console.log("oddate",this.odDate);
        const data=JSON.parse(sessionStorage.getItem("userData"));
        const regdate=data.RegDate;
        console.log("regdate",regdate);
        const odddate = new Date(this.odDate);
      const selectedDate = new Date(regdate);

      const oneYearAgo = new Date(odddate);
      oneYearAgo.setFullYear(odddate.getFullYear() - 1);
      console.log("-----",selectedDate,oneYearAgo)

      if(selectedDate <= oneYearAgo){
        this.setPinCode();
        this.$router.push("/policy-quote");
        return true;
      }
      else{
        if(!this.showPreviousExpiry){
          this.oddateError="Own Damage expiry date should be 1 year or more from the vehicle's registration date";
        }
        else{
          this.oddateError="Previous policy expiry date should be 1 year or more from the vehicle's registration date"
        }
        
        setTimeout(() => {
          this.oddateError="";
        },8000);
        return false;
        
      }
    },
    setPinCode(){
      let allRto = JSON.parse(sessionStorage.getItem('all-rto'));
      let zipCodeObj = allRto && allRto.filter(rto => rto.state===this.userData.state && rto.city===this.userData.city && rto.rtoCode===this.userData.rtoCode);        
      if(zipCodeObj && zipCodeObj[0].zipCode){
        this.userData["pinCode"]=zipCodeObj[0].zipCode;
        sessionStorage.setItem('userData', JSON.stringify(this.userData));
      }
    },
    ViewQuoteNewVehicle(){
      this.userData= JSON.parse(sessionStorage.getItem("userData"));
      this.userData["mobileNumber"]=this.newBikeUser.mobile;
      this.userData["pinCode"]=this.newBikeUser.pincode;
      this.userData["applicablePercentage"]=0;
      this.userData["OdDate"]="";
      sessionStorage.setItem("userData",JSON.stringify(this.userData));
      this.setPinCode();
      this.$router.push("/policy-quote");
    },
    deepEqual(obj1, obj2) {
      if (obj1 === obj2) return true; 
      if (obj1 == null || obj2 == null) return false;
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;
      
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      
      if (keys1.length !== keys2.length) return false; 
      
      for (let key of keys1) {
        if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) return false;
      }
      
      return true;
    },


    getNewData(item,selectedDate='',selectedYear=''){
      this.selectedYear = selectedYear;
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
        this.userData["MfgYear"]=this.selectedYear;
        sessionStorage.setItem("userData",JSON.stringify(this.userData));
        this.$emit('item-clicked', {"key":this.displayStepData, "value":item, "selectedDate":selectedDate, "selectedYear":selectedYear});
    },
    filterData() {
      if (this.searchData.length === 0) {
        this.filteredResultData = [];
        return;
      }
      else {
        if (this.displayStepData == "carMake") {
          const searchTerm = this.searchData.toLowerCase();
          this.filteredResultData = this.newItem.filter(item =>
            item.makeDescription.toLowerCase().startsWith(searchTerm.toLowerCase()))
        }
        else if (this.displayStepData == "model") {
          const searchTerm = this.searchData.toLowerCase();
          this.filteredResultData = this.newItem.filter(item =>
            item.modelDesc.toLowerCase().startsWith(searchTerm.toLowerCase()))
        }
        else if (this.displayStepData == "variant") {
          const searchTerm = this.searchData.toLowerCase();
          this.filteredResultData = this.newItem.filter(item =>
            item.variantDesc.toLowerCase().startsWith(searchTerm.toLowerCase()))
        }
        else if (this.displayStepData == "fuelType") {
          const searchTerm = this.searchData.toLowerCase();
          this.filteredResultData = this.newItem.filter(item =>
            item.fuelType.toLowerCase().startsWith(searchTerm.toLowerCase()))
        }
        else {
         
          const searchTerm = this.searchData.toLowerCase();
          this.filteredResultData = this.newItem.filter(item =>
            item.toLowerCase().startsWith(searchTerm.toLowerCase()))
        }

        if(this.filteredResultData.length > 0){
          this.noSearchMatch = false;
        }else{
          this.noSearchMatch = true;
        }
      }
      console.log(this.displayedData);
    },

    filterUniqueValues(property) {
      const uniqueValues = new Set();
      this.resultData.forEach(item => {
        uniqueValues.add(item[property]);
      });
      return Array.from(uniqueValues);
    },
  },
  watch: {
    resultData: {
      immediate: true,
      handler(newVal) {
        this.filteredResultData = newVal;
      },
    },
    displayStepData: {
      immediate: true,
      handler() {
        this.filterData();
      },
    },
    
    
 
    
  },
  computed: {
    displayedData() {
      // Return the sliced data based on showAllItems
      if (this.showAllItems) {
        return this.data;
      } else {
        return this.data.slice(0, 10);
      }
    },
    minDate() {
      if(this.quoteCategory=="New"){
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        let dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
      }
      else{
        return `${1950}-${10}-${11}`;
      }
    
},
    maxDate() {
      // Calculate today's date
      let today = new Date();
      // Calculate max date as today + 3 days
      let maxDate = new Date(today);
      if(this.quoteCategory=="Roll-Over"){
        maxDate.setMonth(maxDate.getMonth() - 10);
        let yyyy = maxDate.getFullYear();
        let mm = String(maxDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        let dd = String(maxDate.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
      }
      if(this.quoteCategory=="New"){
        maxDate.setDate(maxDate.getDate() + 30);
        let yyyy = maxDate.getFullYear();
        let mm = String(maxDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        let dd = String(maxDate.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
      }
      
    },
    maxOdTpDate() {
      const today = new Date();
      const maxDate = new Date(today);
      maxDate.setDate(today.getDate() + 45);
      return maxDate.toISOString().split('T')[0]; // Formats the date as YYYY-MM-DD
    },

    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/select-card.scss";
@import "../../assets/scss/components/filters.scss";
@import "../../assets/scss/components/select-RTO.scss";
.highlightColor {
 background-color: $main-bg; /* Example */
  p{
    color: rgb(255, 255, 255); /* Example */ 
    opacity: 1;
  }
  img{
    filter: invert(6);
  }
  &:hover{
    p{
      color: rgb(255, 255, 255);
    }
  }
}
.error-text {
  color: $error-txt !important;
  font-family: $fontFamily;
  font-size: 12px;
}
.dark {
    .commonicon{
    filter: invert(1);
  }
}
</style>
