<template lang="">
  <div class="row justify-content-center " >
    <div class="col-12 col-lg-12 col-md-12 col-xl-12">
      <div class="productheader">
        <div class="">
          <div class=" d-flex justify-content-between w-100  gap-2 ">
            <img
              class="productImage"
              :src=this.insCompanyLogo
            />
            <div>
               <h2 class="journeyId mt-2 text-end">
                <!-- <span class="productSpan">Premium Amount</span> -->
                &#8377; {{currency(totalPremiumWithTax)}}
                <!-- <p class="productSpan">(Prize Excusive of GST)</p> -->
            </h2>
            <div class="text-end d-flex align-items-center flex-wrap justify-content-end">
                <span @click="showBreakupmodal()" class="text-primary show-breakup-link"
                 style="font-size: 12px;text-decoration: underline;">Premium Breakup</span>             
             </div>
            </div>
            
          </div>
          <div class="formobileview">
                <div class="single-quote my-2 headingcards d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="mb-0">
                      {{ motorProductType === '4W-Pvt' ? 'Car Details' : 'Bike Details' }}
                    </h6>
                    <p class="mb-0">
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.rtoCode }}&nbsp;&nbsp;</span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.carMake?.makeDescription }}&nbsp;&nbsp;</span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.model?.modelDesc }}&nbsp;&nbsp; </span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.fuelType?.fuelType.toUpperCase()}}&nbsp;&nbsp;</span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.variant?.variantDesc }}&nbsp;&nbsp; </span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        formatDateToMonthString(this.userData.RegDate)}} </span>
                    </p>
                  </div>
                  <div class="text-center mt-2 p-1" v-if="selectedPlanData != undefined">
                    <a :href="selectedPlanData.supportingData.Messages.brochureLink" target="_blank" data-bs-toggle="tooltip" class="downloadBroucher" data-bs-placement="top" title="Download Brochure">
                      <img src="../../assets/images/pdf.svg" class="pdficon"  />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          <div class="d-flex align-items-center flex-wrap gap-2">
                <!-- <h2 class="productName mt-2 mb-0">{{ this.productName}}</h2> -->
            <!-- <span class="productSpan">({{this.policyType}})</span> -->
            </div>
          <div class=" w-100 mt-2 ">
            <div class="d-flex justify-content-between mb-2" style="gap:20px" >
          <span class="productSpan">Reference Number</span>
          <p class="wizard-hold d-flex  gap-1 mb-0">
            <div class="journeyId">{{journey_id}}</div>
            <img src="@/assets/images/copy-icon.svg" width="15" class="copy-icon" @click="copyToClipboard" v-if="!copySuccess"/>
            <img src="@/assets/images/accept.png" width="15" v-if="copySuccess" />
          </p>
          </div>

            
          </div>
        </div>
        <div class="" v-if="requestJson!= undefined && requestJson.product != undefined && requestJson.product.idvDetails != undefined">
          <div class="d-flex justify-content-between mb-2" style="gap:20px" >
          <p class="productSpan " >Policy Type</p>
          <p class="journeyId" >{{this.productName}}</p>
          </div>
          <div class="d-flex justify-content-between mb-2" style="gap:20px" >
          <p class="productSpan " >Policy Holder Type</p>
          <p class="journeyId" >{{this.policyHolder.charAt(0).toUpperCase() + this.policyHolder.slice(1)}}</p>
          </div>
          <template v-if="this.policyType!=='StandaloneTP(1)'">
            <div class="d-flex justify-content-between mb-2" style="gap:20px" v-if="requestJson.product.idvDetails.insuredDeclaredValue != undefined">
            <p class="productSpan ">IDV</p>
            <p class="journeyId">&#8377;{{currency(requestJson.product.idvDetails.insuredDeclaredValue)}}</p>
            </div>
          </template>
          <div class="d-flex justify-content-between mb-2" style="gap:20px" v-if="requestJson.product.registrationDetails != undefined">
          <p class="productSpan ">Registration Date</p>
          <p class="journeyId">{{formatDateToMonthString(requestJson.product.registrationDetails.registrationDate)}}</p>
          </div>
          <div class="d-flex justify-content-between mb-2" style="gap:20px" v-if="requestJson.product.registrationDetails != undefined">
          <p class="productSpan ">Registration Number</p>
          <p class="journeyId">{{requestJson.product.registrationDetails.registrationNumber}}</p>
          </div>
          <template v-if="quoteCategory !== 'New'">
            <template v-if="this.policyType!=='StandaloneTP(1)'">
              <div class="d-flex justify-content-between mb-2" style="gap:20px" v-if="requestJson.product.ncbDetails != undefined">
              <p class="productSpan ">Current NCB</p>
              <p class="journeyId">{{String(selectedPlanData.productDetails.motorNCBPremium[0].nextNcbSlabDetails[0].nextNcbSlabApplicable).trim()}}%</p>
              </div>
            </template>
          </template>
          <template v-if="this.productName=='Comprehensive'">
            <div class="d-flex justify-content-between mb-2" style="gap:20px" v-if="requestJson.product.previousPolicyDetails != undefined">
            <p class="productSpan ">Previous Policy Expiry Date</p>
            <p class="journeyId">{{formatDateToMonthString(requestJson.product.previousPolicyDetails.previousPolicyEndDate)}}</p>
            </div>
          </template>
          <template v-if="this.policyType=='StandaloneOD(1)'">
            <div class="d-flex justify-content-between mb-2" style="gap:20px" v-if="requestJson.product.previousPolicyDetails != undefined">
              <p class="productSpan ">Previous Policy OD Expiry Date</p>
              <p class="journeyId">{{formatDateToMonthString(requestJson.product.previousPolicyDetails.previousPolicyOdExpiryDate)}}</p>
            </div>
            <div class="d-flex justify-content-between mb-2" style="gap:20px" v-if="requestJson.product.previousPolicyDetails != undefined">
              <p class="productSpan ">Previous Policy TP Expiry Date</p>
              <p class="journeyId">{{formatDateToMonthString(requestJson.product.previousPolicyDetails.previousPolicyTpExpiryDate)}}</p>
            </div>
            <!-- <div class="d-flex justify-content-between mb-2" style="gap:20px" v-if="requestJson.product.previousPolicyDetails != undefined">
              <p class="productSpan ">Previous Policy Expiry Date</p>
              <p class="journeyId">{{formatDateToMonthString(requestJson.product.previousPolicyDetails.previousPolicyEndDate)}}</p>
            </div> -->
          </template>

          <template v-if="this.policyType=='StandaloneTP(1)'">
            <div class="d-flex justify-content-between mb-2" style="gap:20px" v-if="requestJson.product.previousPolicyDetails != undefined">
              <p class="productSpan ">Previous Policy Expiry Date</p>
              <p class="journeyId">{{formatDateToMonthString(requestJson.product.previousPolicyDetails.previousPolicyEndDate)}}</p>
            </div>
          </template>
        </div>
        
    </div>
  </div>

  <AmountBreakup v-if="this.openAmountBreakupflag" @emitClose="emitClose()" />
</template>
<script>
import AmountBreakup from "@/modals/amountBreakup.vue";
export default {
  name: "productheader",
  components: {
    AmountBreakup,
  },
  props: [],
  mounted() {
    this.motorProductType = this.$store.state.motorProductType;
    this.quoteCategory = this.$store.state.quoteCategory;
    if (sessionStorage.getItem("userData")) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
      console.log(this.userData, "userData");
    }
    if (JSON.parse(sessionStorage.getItem("journey_id"))) {
      this.journey_id = JSON.parse(sessionStorage.getItem("journey_id"));
    }
    this.getSelectedPlanData();
    this.policyHolder = JSON.parse(sessionStorage.getItem('user-type'));
    this.requestJson = JSON.parse(sessionStorage.getItem("quote_request"));
  },
  data() {
    return {
      userData: {},
      openAmountBreakupflag: false,
      journey_id: "",
      selectedPlan: "",
      productName: "",
      policyType: "",
      insCompanyLogo: "",
      totalPremiumWithTax: "",
      motorProductType: "",
      copySuccess: false,
      requestJson: {},
      policyHolder: "",
      selectedPlanData: JSON.parse(sessionStorage.getItem('selected_plan_data')),
      quoteCategory: ""
    };
  },
  methods: {
    copyToClipboard(){
      navigator.clipboard.writeText(this.journey_id)
        .then(() => {
          this.copySuccess = true;
          setTimeout(() => {
            this.copySuccess = false;
          }, 2000);
        })
        .catch(err => console.error("Failed to copy text: ", err));
    },
    convertToThousandsWithKorL(number) {
      const number1 = parseFloat(number);
      if (number1 >= 100000) {
        // 1 lakh (100,000) or more
        return (number1 / 100000).toFixed(1) + 'L';
      } else if (number1 >= 1000) {
        // 1 thousand (1,000) or more
        return (number1 / 1000).toFixed(1) + 'k';
      }
      return number1.toString();
    },
    formatDateToMonthString(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
    },
    // Date fromatting to DD-MM-YYYY Fromat
    formatDate(date) {
      const dateObj = new Date(date);
      const day = String(dateObj.getDate()).padStart(2, "0");
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const year = dateObj.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    showBreakupmodal() {
      console.log("clicked-------");
      this.openAmountBreakupflag = true;
    },
    emitClose() {
      this.openAmountBreakupflag = false;
    },
    getSelectedPlanData() {
      //  An interval to check for the selected plan
      const timer = setInterval(() => {
        const planData = sessionStorage.getItem("selected_plan_data");
        if (planData) {
          this.selectedPlan = JSON.parse(planData);
          if (this.selectedPlan) {
            clearInterval(timer);
            this.productName =
              this.selectedPlan.insuranceAndProducts.productName;
            this.policyType = this.selectedPlan.insuranceAndProducts.policyType;
            this.insCompanyLogo =
              this.selectedPlan.supportingData.insCompanyLogo;
            this.totalPremiumWithTax =
              this.selectedPlan.productDetails.totalPremiumDetails[0].totalPremiumWithTax;
          }
        }
      }, 200);
    },
    currency(amt) {
      const tmpAmt = parseFloat(amt).toFixed(2);
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDateChange(userDate){
      const date = new Date(userDate);
      const formattedDate = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
      return formattedDate;
    }
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/productDetailsHeader.scss";
@import "../../assets/scss/components/form-wizard.scss";
.show-breakup-link {
  font-size: 12px;
  // text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
}
.dark .show-breakup-link {
  color: $main-bg !important;
}
.request_Id{
  position: relative;
  font-size: 11px;
  color: $commonColorDark;
  border-radius: 8px;
  img{
    position: absolute;
    top: -2px;
    left: -28px;
    margin-left: auto;
    cursor: pointer;
    border: none;
    width: 20px;
    height: 20px;
  }
  .copy-icon{
    transform: scale(1.5);
    height: 16px !important;
  }
}
.single-quote span {
    font-size: 10px;
    color: $text-color;
   
}
.pdficon{
  width: 24px !important;
}
.dark {
  .doticon{
    filter: invert(1) !important;
  }
  .copy-icon{
    filter: invert(1) !important;
  }
  .productheader{
      *{
        color: $text-darkColor !important;
      }
      
    }
    .productSpan{
        color: $base-dark-text !important;
      }
      .journeyId{
        color: $base-dark-text !important;
      }
}

</style>
