<template>
  <!-- <div class="loading_block_outer" v-if="loading">
      <Loading />
    </div> -->
  <!-- <div class="text-center" v-if="loading" >
    Loading..

  </div> -->

  <div class="">
    <Header />
    <div class="wrapper">
      <div class="col-3" v-if="loading">
        <div class="snippet" data-title=".dot-spin">
          <div class="stage">
            <div class="dot-spin"></div>
          </div>
        </div>
      </div>

      <!-- desktop version starts here -->
      <div class="dashbrd-wrap">
        <div class="container dashbrd-wrap_container">
          <div class="d-flex justify-content-start align-items-center dashboard-title-wrap">
            <a @click="goBack()" class="btn-back cursor-pointer">
              <img src="~@/assets/images/savings-back.png" alt="" />
            </a>
            <h5>Dashboard</h5>
            <!-- <router-link to="/policy-quote">
            <img src="~@/assets/images/back-btn.png" alt="" />
            Back
          </router-link> -->
          </div>

          <!-- <span class="svg-icon svg-icon-1 position-absolute ms-6">
                <inline-svg src="@assets/images/search.svg" />
              </span> -->
          <div class="row  floating-form  mb-3 align-items-end dashboard-filters-wrap">
            <div class="form-control1 col-md-2 label-clicked floating-label">
              <label class="form-item-label" style=" font-weight: bold;">Search here</label>
              <input class="form-control form-control-solid w-250px ps-15 floating-input" type="text"
                v-model.trim="searchQuery" v-on:input="resultQuery()" placeholder="Search" style="width: 100%" />
              
            </div>


            <div class="form-control1 label-clicked col-md-2 floating-label">
              <label class="form-item-label" style="font-weight: bold;">Custom Search</label>
              <div class="position-relative">
                <select class="form-control form-control-solid floating-input" style="width: 100%" v-model="fltr">
                <option disabled value="">Select filter</option>
                <option>Journey Id</option>
                <option>Customer Name</option>
                <option>Insurance Company</option>
                <option>Mobile No.</option>
              </select>
              <img src="/images/downarrow.svg" class="downarrowimage">
              </div>

            </div>
            <!-- <div v-if="selected != null"> -->
            <div class="form-control1 label-clicked col-md-2 floating-label">
              <label class="form-item-label" style="font-weight: bold;">Enter Filter
                Text</label>
              <input class="form-control form-control-solid floating-input" placeholder="Edit Filter text" v-model="filterText"
                style="width: 100%" />
            </div>
            <!-- </div> -->
            <div class="form-control1 label-clicked col-md-2 floating-label">
              <label for="startDate" class="form-item-label" style="font-weight: bold;">Start Date</label>
              <div class="position-relative">
                <input class="form-control floating-input" style="width: 100%" type="date" id="startDate"
                name="startDate" v-model="startDate" :max="maxDate" />
              <img src="/images/date.svg" class="downarrowimage">
              </div>
            </div>
            <div class="form-control1 label-clicked col-md-2 floating-label">
              <label for="endDate" class="form-item-label" style="font-weight: bold;">End Date</label>
              <div  class="position-relative">
                <input class="form-control floating-input" style="width: 100%" type="date" id="startDate"
                name="startDate" v-model="endDate" :max="maxDate" />
              <img src="/images/date.svg" class="downarrowimage">
              </div>
            </div>
            <button type="button" class="col-md-1" v-on:click="filter()">
              <img src="../../assets/images/dashboardfilter.svg" />
            </button>
            <div class="col-md-1">
              <button type="button"  v-on:click="reset()">
                <img src="../../assets/images/refresh.svg" style="width: 15px;height: 15px;" />
              </button>
            </div>
            <button v-if="rmData && rmData.RoleId && rmData.RoleId === '3'" type="button"
              class="btn btn-default btn-filter" v-on:click="fileUpload()">
              <i><img src="~@/assets/images/file_upload.svg" /></i>
            </button>


          </div>
          <div class="d-flex justify-content-center">
            <span id="errorList">{{ this.errorList }}</span>
          </div>
          <table class="table dashbrd-table">
            <thead>
              <tr>
                <th v-on:click="sorting('journey_id')" style="cursor: pointer" id="dahboard-proposal-no">
                  Journey Id <img src="~@/assets/images/icons8-sort-10.png" />
                </th>
                <th v-on:click="sorting('proposal_manu_id')" style="cursor: pointer" id="dahboard-proposal-no">
                  Application <br>Number <img src="~@/assets/images/icons8-sort-10.png" />
                </th>
                <th v-on:click="sorting('name')" style="cursor: pointer">
                  Customer <br>Name <img src="~@/assets/images/icons8-sort-10.png" />
                </th>
                <th v-on:click="sorting('name')" style="cursor: pointer">
                  Line of <br>Business <img src="~@/assets/images/icons8-sort-10.png" />
                </th>

                <th v-on:click="sorting('insurance_company_code')" style="cursor: pointer">
                  Insurance <br>Company <img src="~@/assets/images/icons8-sort-10.png" />
                </th>
                <th v-on:click="sorting('mobile_number')" style="cursor: pointer">
                  Mobile <br>Number <img src="~@/assets/images/icons8-sort-10.png" />
                </th>
                <th v-on:click="sorting('created_at')" style="cursor: pointer">
                  Proposal <br>Initiate Date <img src="~@/assets/images/icons8-sort-10.png" />
                </th>
                <th v-on:click="sorting('total_premium')" style="cursor: pointer">
                  Premium<br> (₹) <img src="~@/assets/images/icons8-sort-10.png" />
                </th>
                <th v-on:click="sorting('transaction_date')" style="cursor: pointer">
                  Payment <br>Date <img src="~@/assets/images/icons8-sort-10.png" />
                </th>
                <th v-on:click="sorting('value')" style="cursor: pointer">
                  Journey <br>Status <img src="~@/assets/images/icons8-sort-10.png" />
                </th>
                <th width="120">User Name</th>
                <th width="70">User Id</th>
                <th width="70">Role ID</th>
                <th class="col-action" style="cursor: pointer">
                  Actions <img src="~@/assets/images/icons8-sort-10.png" />
                </th>
                <!-- <th></th> -->
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody id="table-accordian">
              <template v-for="(user, index) in sorted" :key="user.proposal_ui_id">
                <tr class="accordion-toggle content-row-inner">
                  <!--td>{{ index }}</td-->
                  <td>{{ user.journey_id }}</td>
                  <td>
                    <span v-if="user.policy_number" class="app_no" style="font-weight: 800 !important; font-size: 11px">{{ user.policy_number }}</span>
                    <span v-else class="app_no" style="font-weight: 800 !important; font-size: 11px">{{ user.proposal_manu_id }}</span>
                  </td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.line_of_business.toUpperCase() }}</td>
                  <td>{{ user.insurance_company_code }}</td>
                  <td>{{ user.mobile_number }}</td>
                  <td>{{ this.changeDateFormat(user.created_at) }}</td>
                  <td style="text-align: right">{{ user.total_premium }}</td>
                  <td>{{ this.changeDateFormat(user.transaction_date) }}</td>
                  <td>
                    <span v-if="isResumeJourney(user)" class="cursor-pointer text-status" :class="getResumeColor(user)"
                      @click="resumeProposalJourney(user, user.value)">
                      {{ user.value }}
                    </span>
                    <span v-else-if="isReadOnlyPage(user)" class="value cursor-pointer clr-green"
                      @click="goToReadOnlyPage(user.journey_id)">
                      {{ user.value }}
                    </span>
                    <span v-else class="clr-green" style="cursor: pointer;">
                      {{ user.value }}
                    </span>
                  </td>
                  <td>{{ user.user_name }}</td>
                  <td>{{ user.user_id }}</td>
                  <td>{{ user.role }}</td>
                  <td class="td-action">
                    <div class="hover-action d-flex">
                      <div class="col-width d-flex justify-content-between align-items-center m-1"
                        v-if="retriggerPaymentEnable(user)">
                        <button type="button" class="btn btn-primary title" data-bs-toggle="modal"
                          data-bs-target="#kt_Status_modal" title="" v-on:click="this.retriggerPayment(user)">
                          <span class="tooltiptext">Resend payment link to customer</span>
                          <img src="~@/assets/images/currency_rupee.png"
                            class="col-width d-flex justify-content-center align-items-center" alt=""
                            title="Resend payment link to customer" />
                        </button>
                      </div>
                    </div>
                  </td>
                  <!-- <td>
                    <button type="button" 
                    data-bs-toggle="collapse"
                    :data-bs-target="`#demo${user.proposal_ui_id}`" 
                    v-on:click="getRequirements(user, index)"
                    class="btn-accordian"></button>
                    <div class="border"></div>
                </td> -->
                </tr>
                <tr>
                  <td colspan="15" class="hiddenRow p-0">
                    <div class="accordian-body collapse" :id="`demo${user.proposal_ui_id}`"
                      data-parent="#table-accordian">
                      <table v-if="index == childStatus" class="table mb-0">
                        <thead>
                          <tr class="inner-table-header">
                            <th>Application Number</th>
                            <th>Category</th>
                            <th>Status</th>
                            <th>Description</th>
                            <th>Request Date</th>
                            <th>Exam Date</th>
                            <th>Received Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- <template
                          v-for="(data, index) in requirements"
                          :key="index"
                        > -->
                          <template v-for="item in requirements" :key="item.proposal_ui_id">
                            <!-- v-if="user.proposal_ui_id === item.proposal_ui_id" -->
                            <tr class="accordion-toggle inner-table-content">
                              <td>{{ item.applicationNumber }}</td>
                              <td>{{ item.category }}</td>
                              <td>{{ item.insurance_company_status }}</td>
                              <td>{{ item.description }}</td>
                              <td>{{ item.request_date }}</td>
                              <td>{{ item.exam_date }}</td>
                              <td>{{ item.received_date }}</td>
                            </tr>
                          </template>
                          <!-- </template> -->
                          <!-- </td>
                          </tr> -->
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <!-- <div class="d-flex justify-content-center">
            <span id="errorList"> {{this.errorList[0]}}</span>
          </div> -->
          <div class="d-flex justify-content-between">
            <div class="p-2" v-if="totalRecords > 0">

              Displaying {{ startRange }} to {{ enditems }} items from
              {{ totalRecords }}
            </div>
            <div v-else class="p-2">
              No records found
            </div>

            <div class="ml-auto p-2" v-if="totalRecords > 0">
              <ul class="pagination">
                <li class="page-item">
                  <span :disabled="this.prevDisabled" class="page-link" style="cursor: pointer"
                    v-on:click="firstPage()">
                    <span>&laquo;</span>
                  </span>
                </li>
                <li class="page-item">
                  <span :disabled="this.nextDisabled" class="page-link" style="cursor: pointer" v-on:click="previous()">
                    <span>&lsaquo;</span>
                  </span>
                </li>
                <li class="page-item">
                  <span class="page-link">Page</span>
                </li>
                <li class="page-item">
                  <span class="page-link">{{ displayPage }}</span>
                </li>
                <li class="page-item">
                  <span class="page-link">of {{ totalPages }}</span>
                </li>
                <li class="page-item">
                  <span :disabled="this.nextDisabled" class="page-link" style="cursor: pointer" v-on:click="next()">
                    <span>&rsaquo;</span>
                  </span>
                </li>
                <li class="page-item">
                  <span :disabled="this.nextDisabled" class="page-link" style="cursor: pointer" v-on:click="lastPage()">
                    <span>&raquo;</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- desktop version ends here -->
      <!-- mobile version starts here -->
      <div class="dashboard-mobile-version">
        <div class="d-flex justify-content-start align-items-center dashboard-title-wrap">
          <a @click="goBack()" class="btn-back cursor-pointer">
            <img src="~@/assets/images/savings-back.png" alt="" />
          </a>
          <h5>Dashboard</h5>
          <button type="button" class="btn btn-primary btn-filter d-sm-none ml-auto"
            v-on:click="mobileFilters = true, filterOverlay = true">Filter</button>
          <!-- <router-link to="/policy-quote">
          <img src="~@/assets/images/back-btn.png" alt="" />
          Back
        </router-link> -->
        </div>
        <div class="filter_overlay" v-show="filterOverlay" v-on:click="mobileFilters = false, filterOverlay = false">
        </div>
        <div class="dashboard-header dashboard-filters-wrap" :class="{ 'show': mobileFilters }">
          <div class="form-1">
            <div class="form-control1 label-clicked floating-label">
              <select class="form-control form-control-solid" v-model="fltr">
                <option disabled value="">Select filter</option>
                <option>Journey Id</option>
                <option>Customer Name</option>
                <option>Insurance Company</option>
                <option>Mobile No.</option>
              </select><label class="form-item-label Custom-label">Custom Search</label>
            </div>
            <div class="form-control1 label-clicked floating-label">
              <input class="form-control form-control-solid " v-model="filterText" /><label
                class="form-item-label filter-label">Enter Filter Text</label>
            </div>
          </div>
          <div class="form-2">
            <div class="form-control1 label-clicked floating-label">
             <div  class="position-relative">
               <input class="form-control" type="date" id="startDate" name="startDate" v-model="startDate" />
              <img src="/images/date.svg" class="downarrowimage">
             </div>
              <label for="startDate" class="form-item-label start-date-label">Start Date</label>
            </div>
            <div class="form-control1 label-clicked floating-label">
              <div  class="position-relative">
                <input class="form-control" type="date" id="startDate" name="startDate" v-model="endDate" />
              <img src="/images/date.svg" class="downarrowimage">
              </div>
              <label for="endDate" class="form-item-label end-date-label">End Date</label>
            </div>
          </div>
          <div class="cta-action-row">
            <div class="filter-reset">
              <button type="button" class="btn btn-default btn-filter"
                v-on:click="filter(), mobileFilters = false, filterOverlay = false">
                <i><img src="~@/assets/images/icons8-funnel-21.png" /></i>
              </button>
              <button type="button" class="btn btn-default btn-reset"
                v-on:click="reset(), mobileFilters = false, filterOverlay = false">
                <span>Reset</span>
              </button>
            </div>

          </div>

        </div>
        <div class="form-control1 label-clicked floating-label Search-label">
          <input class="form-control form-control-solid w-250px ps-15" type="text" v-model.trim="searchQuery"
            v-on:input="resultQuery()" placeholder="Search" />
          <label class="form-item-label">Search here</label>
        </div>

        <div v-for="user in proposal" :key="user.proposal_ui_id">
          <div class="content-holder">

            <div class="col-width">
              <div class="col-content">
                <h6>Journey Id</h6>
                <b class="value">{{ user.journey_id }}</b>
              </div>
              <div class="col-content">
                <h6>Application Number</h6>
                <b class="value">{{ user.policy_number ? user.policy_number : user.proposal_manu_id }}</b>
              </div>
              <div class="col-content">
                <h6>Customer Name</h6>
                <b class="value">{{ user.name }}</b>
              </div>
              <div class="col-content">
                <h6>Insurance Company</h6>
                <b class="value">{{ user.insurance_company_code }}</b>
              </div>
              <div class="col-content">
                <h6>Mobile Number</h6>
                <b class="value">{{ user.mobile_number }}</b>
              </div>
            </div>
            <div class="col-width">
              <div class="col-content">
                <h6>Purchase Date</h6>
                <b class="value">{{ this.changeDateFormat(user.created_at) }}</b>
              </div>
              <div class="col-content">
                <h6>Premium (&#8377;)</h6>
                <b class="value">{{ user.total_premium }}</b>
              </div>
              <div class="col-content">
                <h6>Payment Date</h6>
                <b class="value">{{ this.changeDateFormat(user.transaction_date) }}</b>
              </div>
              <div class="col-content">
                <h6>Journey Status</h6>
                <span v-if="isResumeJourney(user)" class="value cursor-pointer" :class="getResumeColor(user)"
                  @click="resumeProposalJourney(user)">
                  {{ user.value }}
                </span>
                <span v-else-if="isReadOnlyPage(user)" class="value cursor-pointer clr-green"
                  @click="goToReadOnlyPage(user.journey_id)">
                  {{ user.value }}
                </span>
                <span v-else class="value clr-green">
                  {{ user.value }}
                </span>
              </div>
              <div class="col-content">
                <h6>Actions</h6>
                <div class="col-width d-flex justify-content-between align-items-center">
                  <button v-if="retriggerPaymentEnable(user)" type="button" class="btn btn-primary" data-toggle="modal"
                    v-on:click="this.retriggerPayment(user)" title="Resend payment link to customer">
                    <img src="~@/assets/images/currency_rupee.png"
                      class="col-width d-flex justify-content-center align-items-center" alt=""
                      title="Resend payment link to customer" />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-width d-flex justify-content-between align-items-center">
              <button v-if="enableUploadDoc(user)" type="button" class="btn btn-primary" data-toggle="modal"
                v-on:click="getRequirements(user.proposal_ui_id, index)">
                <img src="~@/assets/images/eye.png" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-center" v-if="totalRecords > 0">

            Displaying {{ startRange }} to {{ enditems }} items from
            {{ totalRecords }}

          </div>
          <div v-else class="p-2">
            No records found
          </div>
          <!-- <div class="d-flex justify-content-center">
            <span id="errorList"> {{this.errorList[0]}}</span>
          </div> -->
          <div class="d-flex justify-content-center" v-if="totalRecords > 0">
            <ul class="pagination">
              <li class="page-item">
                <span class="page-link" style="cursor: pointer" :disabled="this.prevDisabled" v-on:click="firstPage()">
                  <span>&laquo;</span>
                </span>
              </li>
              <li class="page-item">
                <span class="page-link" style="cursor: pointer" :disabled="this.nextDisabled" v-on:click="previous()">
                  <span>&lsaquo;</span>
                </span>
              </li>
              <li class="page-item">
                <span class="page-link">Page</span>
              </li>
              <li class="page-item">
                <span class="page-link">{{ displayPage }}</span>
              </li>
              <li class="page-item">
                <span class="page-link">of {{ totalPages }}</span>
              </li>
              <li class="page-item">
                <span class="page-link" style="cursor: pointer" :disabled="this.nextDisabled" v-on:click="next()">
                  <span>&rsaquo;</span>
                </span>
              </li>
              <li class="page-item">
                <span class="page-link" style="cursor: pointer" :disabled="this.nextDisabled" v-on:click="lastPage()">
                  <span>&raquo;</span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ResendPaymentLinkStatus :status="this.linkStatus" :disablebtn="this.disablebtn" :errorStatus="this.errorStatus">
  </ResendPaymentLinkStatus>
  <!-- mobile version ends here -->
</template>


<script>
import axios from "axios";
// import Header from "@/components/Header.vue";
import { getDistributer} from '@/mixins/common';
// import Loading from "@/components/loading.vue";
// import ResendPaymentLinkStatus from "@/components/ResendPaymentStatusModal.vue"
import $ from "jquery";
export default {
  name: "dashboard",
  props: {
    msg: String,
  },
  mixins: [getDistributer],
  components: {
    //  Header,Loading,ResendPaymentLinkStatus
  },

  async mounted() {

    this.configData = this.$store.state.configData;
    if (this.configData) {
      this.distributorID = this.configData["DISTRIBUTER"]["distributerID"];
    }
    this.loading = true;
    let rmData = JSON.parse(sessionStorage.getItem("rm_data"));
    this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
    this.selectedPlan = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    // console.log("type",typeof(sessionStorage.getItem("filledData")),sessionStorage.getItem("filledData"))
    this.formInfo = JSON.parse(sessionStorage.getItem("filledData"));
    console.log("filled details-", this.formInfo);
    console.log("selected_plan_data", this.selectedPlan);
    this.rmCode = rmData.Userid;

    if (!this.$route.query.id) {
      await this.getdashboardData();
    }
  },
  data() {
    return {
      startRange: 0,
      distributorID: "",
      enditems: 50,
      sortBy: "",
      selectedPlan: [],
      formInfo: [],
      sortDirection: "asc",
      reverse: false,
      searchQuery: "",
      filterText: "",
      hover: {},
      fltr: "",
      selected: "",
      mobileFilters: false,
      filterOverlay: false,
      filterColumn: {
        "Journey Id": "journey_id",
        "Customer Name": "name",
        "Mobile No.": "mobile_number",
        "Insurance Company": "insurance_company_code",
      },
      startDate: "",
      endDate: "",
      totalPages: 0,
      requirements: [],
      proposal: [],
      formInfo: {},
      //   searchProposal:[],
      proposalNumber: "",
      rmCode: "",
      pageNumber: 0,
      pageSize: 50,
      pageSizeArray: [10, 20, 50],
      displayPage: 1,
      nextDisabled: false,
      prevDisabled: true,
      totalRecords: 0,
      loading: false,
      initCust: [],
      // Resume related
      proposalUiId: "",
      uiQuoteId: "",
      proposalDetails: "",
      quoteDetails: "",
      quoteResponseDetails: "",
      status: [],
      flag: false,
      childStatus: "",
      errorList: "",
      errorListTop: [],
      configData: "",
      linkStatus: "",
      disablebtn: true,
      errorStatus: "",
      productName: ""
    };
  },
  methods: {
    // gtpageNumber() {
    //   if (this.goToPageNumber >= 1) {
    //     this.pageNumber = this.goToPageNumber - 1;
    //     await this.getdashboardData();
    //     this.displayPage = this.pageNumber + 1;
    //   }
    // },
    async reset() {
      this.searchQuery = "";
      this.fltr = "";
      this.startDate = "";
      this.endDate = "";
      this.filterText = "";
      this.selected = "";
      this.displayPage = 1;
      await this.getdashboardData();
    },

    sorting(s) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = s;
    },
    isReadOnlyPage(user) {
      return user.value == 'Underwriting' || user.value == 'Policy Issued';
    },
    goToReadOnlyPage(journeyid) {
      console.log('gotoreadonly called: ', journeyid)
      this.$router.push({ path: '/read-only-proposal-details', query: { journeyId: journeyid } });
    },
    resultQuery() {
      this.proposal.splice(0, this.proposal.length, ...this.initCust);
      console.log('resultQuery called: ');
      if (this.searchQuery !== "") {
        console.log('inside search query: ', this.searchQuery);
        let results = [];
        for (let j = 0; j < this.proposal.length; j++) {
          if (this.searchingFunc(this.proposal[j], this.searchQuery)) {
            results.push(this.proposal[j]);
          }
        }
        this.proposal.splice(0, this.proposal.length, ...results);
        this.startRange = this.proposal.length > 0 ? 1 : 0;
        this.enditems = this.proposal?.length;
      }
    },
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
        else if (Number.isInteger(obj[key])) {
          if (obj[key].toString().toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    async filter() {
      this.errorList = ""
      this.errorList = ""
      if (((this.fltr != "") && (this.filterText != "")) || ((this.startDate != "") && (this.endDate != ""))) {
        this.selected = this.filterColumn[this.fltr];
        await this.getdashboardData();
      }
      else if (((this.fltr == "") && (this.filterText == "")) || ((this.startDate != "") && (this.endDate != ""))) {
        await this.getdashboardData();
      }
      else if (((this.fltr == "") && (this.filterText != ""))) {
        this.errorList = ""
        this.errorList = "Please select a filter"
        window.scrollTo(0, 0);
        // alert("Please select a Filter");
      }
      else if (this.filterText == "") {
        this.errorListTop = ""
        this.errorListTop = "Please enter a filter text"
        window.scrollTo(0, 0);
        // alert("Please enter a Filter Text");
      }

    },
    async getdashboardData() {
      this.loading = true;
      this.pageNumber = this.displayPage > 0 ? this.displayPage - 1 : 0;
      let endTime = '23:59:59';
      let startTime = '00:00:00';
      let qData = {
        pageSize: this.pageSize ? this.pageSize : '',
        pageNumber: this.pageNumber,
        columnName: this.selected ? this.selected : '',
        filterText: this.filterText ? this.filterText.toUpperCase() : '',
        startDate: this.startDate ? this.startDate : '',
        endDate: this.endDate ? this.endDate : ''
      };
      let headers = {};

      try {
        const response = await this.backendApi("getDashboardData", qData, headers, "get");
        this.loading = false;

        this.proposal = response.data.data.resp;
        this.errorList = "";
        this.totalRecords = response.data.data.count;
        this.totalPages = Math.ceil(this.totalRecords / 50);
        this.enditems = this.proposal?.length + this.pageNumber * 50;
        this.startRange = this.pageNumber * 50 + 1;

        if (this.totalRecords == 0) {
          this.startRange = 0;
          this.displayPage = 0;
        }

        this.initCust = [];
        this.initCust.splice(0, this.proposal?.length, ...this.proposal);

        if (this.proposal?.length == 0) {
          this.pageNumber = this.displayPage - 1;
        }
        this.resultQuery();
      } catch (error) {
        this.loading = false;
        console.error('An error occurred while fetching dashboard data:', error);
        this.apiError = true;
      }
    },
    async onChangeSize(event) {
      this.pageSize = parseInt(event.target.value);
      this.pageNumber = 0;
      this.displayPage = this.pageNumber + 1;
      await this.getdashboardData();
    },
    async next() {
      if (this.searchQuery != "") {
        this.pageNumber = this.pageNumber + 1;
        this.displayPage = this.pageNumber + 1;
        await this.getdashboardData();
        this.nextDisabled = false;
      } else if (this.proposal.length >= this.pageSize) {
        this.pageNumber = this.pageNumber + 1;
        this.displayPage = this.pageNumber + 1;
        await this.getdashboardData();
        this.nextDisabled = false;
      } else if (this.proposal.length == 0) {
        this.errorList = ""
        this.errorList = "No more proposals data"
        window.scrollTo(0, 0);
        this.nextDisabled = true;
      } else {
        this.errorList = ""
        this.errorList = "No more proposals data"
        window.scrollTo(0, 0);
      }
    },
    async previous() {
      if (this.pageNumber != 0) {
        this.pageNumber = this.pageNumber - 1;
        this.displayPage = this.pageNumber + 1;
        await this.getdashboardData();
      } else {
        this.errorList = ""
        this.errorList = "Already on first page"
        this.prevDisabled = true;
        window.scrollTo(0, 0);
      }
    },
    async firstPage() {
      if (this.pageNumber != 0) {
        this.pageNumber = 0;
        this.displayPage = this.pageNumber + 1;
        await this.getdashboardData();
      } else {
        this.errorList = ""
        this.errorList = "Already on first page"
        // alert("Already on first page");
        this.prevDisabled = true;
      }
    },
    displayItems() {
      // if((this.totalRecords) <= 50)
      // {
      //   this.items = this.totalRecords;
      //   this.displayPage = 1;
      // }
      // else if(this.displayPage*50 > this.totalRecords)
      // {
      //   this.items = this.totalRecords;
      //   // this.displayPage = 1;
      // }
      // else
      // {
      //   this.items = this.displayPage*50;
      // }
      this.enditems = this.proposal.length + this.pageNumber * 50;
    },
    async lastPage() {
      if (this.enditems != this.totalRecords) {
        this.displayPage = Math.ceil(this.totalRecords / 50);
        this.pageNumber = this.displayPage - 1;
        this.enditems = this.totalRecords;
        await this.getdashboardData();
      } else {
        this.errorList = ""
        this.errorList = "No more proposals"
        window.scrollTo(0, 0);
      }
    },
    enableUploadDoc(user) {
      if (user.insurance_company_code == "HDFC") {
        return false;
      } else if (user.insurance_company_code == "TATA") {
        return user.value == "Proposal Submitted" || user.value == "Requirements pending";
      } else if (user.insurance_company_code == "ICICI") {
        return user.value == "Proposal Submitted" || user.value == "Requirements pending";
      } else {
        return false;
      }
    },
    getProposalById(user) {
      const journey_id = user.journey_id;
      const qData = [journey_id, "Proposal Review"];
      var headers = {
        Authorization:
          "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      this.backendApi("getProposalWithStage", qData, headers, "get")
        .then((response) => {
          this.proposalDetails = response.data.data.proposal;
          sessionStorage.setItem("filledData", JSON.stringify(this.proposalDetails.proposal_json));
          sessionStorage.setItem("proposal_ui_id", JSON.stringify(this.proposalDetails.proposal_ui_id));
          let spdetail_flag = process.env.VUE_APP_SPDETAIL_ONESB;
          if (spdetail_flag == 1) {
            if (sessionStorage.getItem("rm_data")) {
              this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
              this.getSPDetailsOneSB(this.rmData.UserId, response.data.data.quote_response.insurance_company_code);
            }
          }
          this.quoteDetails = response.data.data.quote_request;
          this.userDetails = response.data.data.user;

          localStorage.setItem("userProfile", JSON.stringify(this.userDetails));
          sessionStorage.setItem(
            "user_profile",
            JSON.stringify(this.userDetails)
          );
          sessionStorage.setItem(
            "quote_request",
            JSON.stringify(this.quoteDetails.quote_request_json)
          );
          this.quoteResponseDetails = response.data.data.quote_response;
          let pln = this.quoteResponseDetails.quote_response_data;
          sessionStorage.setItem("selected_plan_data", JSON.stringify(pln));
          sessionStorage.setItem(
            "selectedPlanForCheckout",
            JSON.stringify(pln)
          );
          sessionStorage.setItem(
            "resumeProfile",
            JSON.stringify(this.userDetails)
          );
          sessionStorage.setItem(
            "resume_proposal_flag",
            JSON.stringify(1)
          );
          sessionStorage.setItem('journey_id', JSON.stringify(user.journey_id));
          this.$store.commit('setJourneyId', user.journey_id);
          sessionStorage.setItem('resumeFromDashboard', JSON.stringify(true));
          setTimeout(() => {
            if (user.value === 'KYC Failed') {
              this.$router.push("/ckyc-form");
            } else {
              this.$router.push("/process-wizard");
            }
          }, 1000);
        })
        .catch((error) => {
          console.log("error msg block dp", error);
        });
    },
    getSPDetailsOneSB(rm_code, man_code) {
      let reqData = [man_code];
      let headers = {};
      this.backendApi("getSPDetailsOneSB", reqData, headers, "get")
        .then((response) => {
          if (response == 'error') {
            self.apiError = true;
          } else if (response && response.data) {
            console.log('response sp details: ', response.data);
            if (response.data.status == "failed") {
              //redirection to error page and on go back button redirect to homepage
              let msg = ['SP details are not available']
              this.$router.push({ path: '/networkalert', query: { backUrl: 'policy-quote', msg: msg } });
            }
            sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.data));
            sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.log('error msg block spdetailOneSB API', error);
          let msg = ['SP details are not available']
          this.$router.push({ path: '/networkalert', query: { backUrl: 'policy-quote', msg: msg } });
        });
    },
    // getQuoteDetails(ui_quote_id) {
    //   let qData = [ui_quote_id];
    //   var headers = {
    //     Authorization:
    //       "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
    //     "Content-Type": "application/json",
    //   };
    //   this.backendApi("getQuoteDetails", qData, headers, "get")
    //     .then((response) => {
    //       this.quoteDetails = response.data.data[0];
    //       // this.uiQuoteId = this.proposalDetails.ui_quote_id;

    //       this.userDetails = {
    //         gender: this.quoteDetails.gender,
    //         fullname: this.quoteDetails.life_assured_name,
    //         dob: this.quoteDetails.dob,
    //         age: null,
    //         mobile: this.quoteDetails.mobile_number,
    //         zipcode: this.quoteDetails.zipcode,
    //         annualincome: this.quoteDetails.annual_income,
    //         inlakhs: null,
    //         employmenttype: this.quoteDetails.employee_type,
    //         education: this.quoteDetails.education,
    //         smoke: this.quoteDetails.smoker,
    //         whatsapp: false,
    //         contactDetails: {
    //           mobileNumber: this.quoteDetails.mobile_number
    //         }
    //       };

    //       sessionStorage.setItem("resumeProfile", JSON.stringify(this.userDetails));
    //       // sessionStorage.setItem(
    //       //   "user_profile",
    //       //   JSON.stringify(this.userDetails)
    //       // );
    //       sessionStorage.setItem(
    //         "resumeProfile",
    //         JSON.stringify(this.userDetails)
    //       );
    //       sessionStorage.setItem(
    //         "resume_proposal_flag",
    //         JSON.stringify(1)
    //       );
    //       sessionStorage.setItem(
    //         "request_json",
    //         JSON.stringify(this.quoteDetails.quote_request_json)
    //       );

    //       // this.getQuoteResponseDetails(this.uiQuoteId);
    //     })
    //     .catch((error) => {
    //       console.log("error msg block dp", error);
    //     });
    // },

    // getQuoteResponseDetails(ui_quote_id) {
    //   console.log('getQuoteResponseDetails called: ', ui_quote_id)
    //   let qData = [ui_quote_id];
    //   var headers = {
    //     Authorization:
    //       "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
    //     "Content-Type": "application/json",
    //   };
    //   this.backendApi("getQuoteResponseDetails", qData, headers, "get")
    //     .then((response) => {
    //       console.log("quote response details: ", response.data.data);
    //       this.quoteResponseDetails = response.data.data;
    //       //let pln = JSON.parse(this.quoteResponseDetails.quote_response_data);
    //       let pln = this.quoteResponseDetails.quote_response_data;
    //       sessionStorage.setItem("selected_plan_data", JSON.stringify(pln));
    //       sessionStorage.setItem(
    //         "selectedPlanForCheckout",
    //         JSON.stringify(pln)
    //       );

    //       //let reqData = [this.userDetails.mobile, this.userDetails.dob];
    //       let reqData = [this.userDetails.mobile, this.proposalDetails.dob];
    //       // this.getLogData(reqData);
    //       sessionStorage.setItem(
    //         "filledDataTab",
    //         JSON.stringify(this.proposalDetails.tag_name)
    //       );
    //       sessionStorage.setItem(
    //         "currentTab",
    //         this.proposalDetails.tag_name
    //       );
    //       let productGroupId = JSON.parse(sessionStorage.getItem("filledData")).productGroupId;
    //       setTimeout(() => {
    //         // if(productGroupId == 'health') {
    //         this.$router.push("/proposer-details");
    //         // } else {
    //         //   this.$router.push("/proposal");
    //         // }

    //       }, 1000);
    //     })
    //     .catch((error) => {
    //       console.log("error msg block quote response", error);
    //     });
    // },
    getLogData(qData) {
      var headers = {
        Authorization:
          "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      this.backendApi("getLogData", qData, headers, "get")
        .then((response) => {
          console.log("log data response details: ", response.data.data);
          this.logDetails = response.data.data;
          sessionStorage.setItem(
            "filledDataTab",
            JSON.stringify(this.logDetails.tag_name)
          );
          sessionStorage.setItem(
            "currentTab",
            JSON.stringify(this.logDetails.tag_name)
          );

          let productGroupId = JSON.parse(sessionStorage.getItem("filledData")).productGroupId;

          console.log('productGroupId: ', productGroupId);

          setTimeout(() => {
            if (productGroupId == 'health') {
              this.$router.push("/proposer-details");
            } else {
              this.$router.push("/proposal");
            }

          }, 1000);
        })
        .catch((error) => {
          console.log("error msg block dp", error);
        });
    },
    async personalDetails(journey_id) {
      const qData = [journey_id, "Proposal Review"];
      var headers = {
        Authorization:
          "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      const response = await this.backendApi("getProposalWithStage", qData, headers, "get");
      if (response && response.data && response.data.data) {
        this.productName = response.data.data.quote_response.quote_response_data.insuranceAndProducts.productName
        var fullName = '';
        if (response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.middleName && response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.middleName.input) {
          fullName = response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input + " " + response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.middleName.input + " " + response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.lastName.input;
        } else {
          fullName = response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input + " " + response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.lastName.input
        }
        const result = {
          fullName: fullName,
          email: response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.email.input,
          mobileNumber: response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input,
        }
        return result;
      }
      const result = {
        fullName: "",
        email: "",
        mobileNumber: "",
      }
      return result;
    },
    async retriggerPayment(user) {
      this.loading = true;
      this.disablebtn = true;
      this.errorStatus = 'sending'
      this.linkStatus = "Resending Payment Link"
      const personalDetails = await this.personalDetails(user.journey_id);
      const distributorID = getDistributer()["distributerID"];
      const rmData = JSON.parse(sessionStorage.getItem("rm_data"));
      const resumeLink = new URL(location).origin + "/#/proposal-details-check-diy?journeyId=" + user.journey_id + '&transactionId=' + rmData['transaction_id'];
      const reqData = {
        "distributor": {
          "distributorID": distributorID,
          "agentID": "",
          "agentType": rmData.RoleId == "4" ? "POSP" : "",
          "salesChannel": "",
          "channelType": rmData.RoleId !== "1" ? "B2B" : "B2C",
          "varFields": [{
            "fieldName": "",
            "fieldValue": ""
          }]
        },
        "eventName": "reviewAndPay",
        "data": [
          {
            "resumeLink": resumeLink,
            "mobileNumber": personalDetails.mobileNumber,
            "channel": "sms"
          },
          {
            "resumeLink": resumeLink,
            "fullName": personalDetails.fullName,
            "productName": this.productName,
            "emailId": this.getEmailIds(personalDetails.email),
            "channel": "email"
          }
        ]

      }
      const headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        "Content-Type": "application/json",
      };
      const response = await this.backendApi('sendEmailandSMS', reqData, headers);
      if (response && response.data && response.data.data.msg === "User communicated successfully") {
        this.linkStatus = response.data.data.msg
        this.disablebtn = false;
        this.loading = false;
        this.errorStatus = "success";
      }
      else {
        this.linkStatus = response.data.errors[0].errorDisplayMessage;
        this.disablebtn = false;
        this.errorStatus = "error";
      }
      console.log("response ==> ", response);
    },
    getEmailIds(email) {
      if (getOperationEmail().includes(email)) {
        return [...getOperationEmail()];
      } else {
        return [email, ...getOperationEmail()]
      }
    },
    retriggerPaymentEnable(user) {
      if (['OTP Verified', 'Proposal Submitted', 'Awaiting Client Approval', 'Payment Failure', 'Payment Success', 'Proposal Error', 'Payment Initiated'].includes(user.value)) {
        return true;
      }
      return false;
    },
    getResumeColor(user) {
      if (user.value == 'Proposal Application Pending' || user.value == 'Awaiting Client Approval') {
        return 'clr-yellow';
      } else if (user.value == 'Proposal Error') {
        return 'clr-red';
      } else {
        return 'clr-green';
      }
    },
    isResumeJourney(user) {
      return user.value == 'Proposal Application Pending' || user.value == 'Proposal Modification Requested' || user.value == 'Awaiting Client Approval' || user.value == 'Proposal Error' || user.value == 'KYC Success' || user.value == 'KYC Failed';
    },
    resumeProposalJourney(user) {
      this.getProposalById(user);
    },
    getProposal(journey_id) {
      let qData = [journey_id, "Proposal Review"];
      var headers = {
        Authorization:
          "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      return this.backendApi("getProposalWithStage", qData, headers, "get")
        .then((response) => {
          this.proposalDetails = response.data.data.proposal;
          let resp = JSON.parse(this.proposalDetails.proposal_json)
          sessionStorage.setItem("filledData", this.proposalDetails.proposal_json);
          return resp;
        }) //proposal_ui_id, uiQuoteId,
        .catch((error) => {
          console.log("error msg block dp", error);
        });
    },
    // getQuote(ui_quote_id) {
    //   let qData = [ui_quote_id];
    //   var headers = {
    //     Authorization:
    //       "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
    //     "Content-Type": "application/json",
    //   };
    //   return this.backendApi("getQuoteResponseDetails", qData, headers, "get")
    //     .then((response) => {
    //       console.log("quote response details: ", response.data.data);
    //       this.quoteResponseDetails = response.data.data;
    //       //let pln = JSON.parse(this.quoteResponseDetails.quote_response_data);
    //       let pln = this.quoteResponseDetails.quote_response_data;
    //       console.log(pln)
    //       return pln;
    //     })
    //     .catch((error) => {
    //       console.log("error msg block quote response", error);
    //     });
    // },
    // updateProposalStatus(puid, statusid) {
    //   let qData = {
    //     "proposal_ui_id": puid,
    //     "status_id": statusid
    //   };
    //   var headers = {
    //     Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
    //     "Content-Type": "application/json",
    //   };

    //   this.backendApi("ProposalStatus", qData, headers)
    //     .then((response) => {
    //       if (response == 'error') {
    //         self.apiError = true;
    //       } else if (response && response.data) {
    //         console.log('proposal status updated: ', response.data)
    //       }
    //     })
    //     .catch((error) => {
    //       console.log('error msg block email', error);
    //     });
    // },
    goBack() {
      // this.$router.push("");
      this.$router.push({ path: '/' });
    },
    docuploadlink(user) {
      let qData = [user.journey_id, "Proposal Review"];
      let headers = {};
      this.backendApi("getProposalWithStage", qData, headers, "get")
        .then((response) => {
          this.proposalDetails = response.data.data.proposal;
          // this.uiQuoteId = this.proposalDetails.ui_quote_id;

          sessionStorage.setItem(
            "filledData",
            JSON.parse(this.proposalDetails.proposal_json)
          );
          sessionStorage.setItem(
            "filledData",
            this.proposalDetails.proposal_json
          );
          sessionStorage.setItem(
            "proposal_ui_id",
            JSON.stringify(this.proposalDetails.proposal_ui_id)
          );
          /* sessionStorage.setItem(
            "uiQuoteId",
            JSON.stringify(this.proposalDetails.ui_quote_id)
          ); */

        })
        .catch((error) => {
          console.log("error msg block dp", error);
        });

      let link = "/#/upload-doc?distributorID=" + this.distributorID + "&insuranceCompanyCode=" + user.insurance_company_code + "&applicationNo=" + user.proposal_manu_id + "&productCode=" + user.product_code;
      if (user.value == "Proposal Submitted" || user.value == "Requirements pending") {
        if (user.insurance_company_code == "ICICI") {
          let headers = {
            Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
            "Content-Type": "application/json",
          };
          this.backendApi("getResumeURL", qData, headers, "get")
            .then((response) => {
              if (response == 'error') {
                self.apiError = true;
              } else if (response && response.data) {
                if (response.data[1]['resumeURL'] != '')
                  window.location.href = response.data[1]['resumeURL'];
              }
            })
            .catch((error) => {
              console.log('error msg block dp', error);
            });
        }
        else {
          window.location.href = new URL(location).origin + link;
        }
      }
      // return link;
    },
    getRequirements(user, index) {
      this.requirements = [];
      this.childStatus = index
      const headers = {};

      const qData = [user.journey_id, "Proposal Review"];
      this.backendApi("getProposalWithStage", qData, headers, "get")
        .then((response) => {
          this.proposalDetails = response.data.data.proposal;
          // this.uiQuoteId = this.proposalDetails.ui_quote_id;

          sessionStorage.setItem(
            "filledData",
            JSON.parse(JSON.stringify(this.proposalDetails.proposal_json))
          );
          sessionStorage.setItem(
            "filledData",
            JSON.stringify(this.proposalDetails.proposal_json)
          );
          sessionStorage.setItem(
            "proposal_ui_id",
            JSON.stringify(this.proposalDetails.proposal_ui_id)
          );
          /* sessionStorage.setItem(
            "uiQuoteId",
            JSON.stringify(this.proposalDetails.ui_quote_id)
          ); */

        })
        .catch((error) => {
          console.log("error msg block dp", error);
        });


    },
    changeDateFormat(inp) {
      var str = "";
      let monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      var d;
      if (typeof inp == typeof str) {
        d = new Date(Date.parse(inp));
        str =
          d.getDate().toString() +
          "-" +
          monthNames[d.getMonth()] +
          "-" +
          d.getFullYear().toString();
      }
      return str;
    },
    fileUpload() {
      this.$router.push('/fileUpload');
    },
  },
  computed: {
    sorted: function () {
      if (this.proposal && this.proposal.length > 0) {
        return this.proposal.sort((p1, p2) => {
          let modifier = 1;
          if (this.sortDirection === "desc") modifier = -1;

          if (p1[this.sortBy] == null || p1[this.sortBy] === '') return 1 * modifier;
          if (p2[this.sortBy] == null || p2[this.sortBy] === '') return -1 * modifier;

          if (this.sortBy == "journey_id" || this.sortBy == "total_premium") {
            if (parseInt(p1[this.sortBy]) < parseInt(p2[this.sortBy]))
              return -1 * modifier;
            if (parseInt(p1[this.sortBy]) > parseInt(p2[this.sortBy]))
              return 1 * modifier;
          } else {
            if (p1[this.sortBy].toLowerCase() < p2[this.sortBy].toLowerCase())
              return -1 * modifier;
            if (p1[this.sortBy].toLowerCase() > p2[this.sortBy].toLowerCase())
              return 1 * modifier;
          }
          return 0;
        });
      }
    },

    maxDate() {
      const today = new Date().toISOString().split("T")[0];
      return today;
    },

  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/dashboard.scss";

#goToPageNumber {
  width: 175px;
}



#errorList {
  color: red;

}

#errorListTop {
  color: red;

}

.dot-spin {
  position: fixed;
  right: 50%;
  bottom: 50%;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
    18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {

  0%,
  100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }

  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }

  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }

  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff,
      0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }

  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }

  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
  }

  75% {
    box-shadow: 0 -18px 0 0 #9880ff,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 0 #9880ff;
  }

  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #9880ff;
  }
}

// .pagination .btn-next
// .pagination .btn-prev
//   {
//   background: none;
//   border-radius: 0.375rem;
//   font-weight: 500;
//   font-size: 1.0rem;
//   font-family: Poppins, Helvetica, sans-serif;
// }
.title .tooltiptext {
  visibility: hidden;
  width: 120px;
  text-align: left;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  right: 70px;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.title:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 1439px) and (min-width: 992px) {
  .col-action {
    position: absolute;
    right: 0;
    opacity: 0;
    pointer-events: none;
  }

  .td-action {
    width: auto;
    padding: 0 !important;
    position: absolute !important;
    right: 50px;
    top: 55px;
    background: transparent !important;
  }

  .hover-action {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 5px;
    background-color: #fff !important;
    box-shadow: 0px 1px 3px 1px rgba(153, 153, 153, 0.3);
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 0;
    transition: 0.3s all;
    transform: scale(0);
    transform-origin: top center;
  }

  .hover-action:before {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 5px 7px 5px;
    border-color: transparent transparent rgb(153, 153, 153, 0.5) transparent;
    transform: rotate(0deg);
    position: absolute;
    right: 18px;
    top: -7px;
  }

  .td-action button {
    margin: 0px;
  }

  .content-row-inner:hover .hover-action {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }
}
</style>