<template>
    <div class="modal-overlay breakupmodal">
        <div class="modal-dialog-breakup p-3" role="document">
            <div class="modal-content">
                <div class="modal-header justify-content-between align-items-center  mb-3">
                    <h5 class="modal-title text-align-center">
                        <!-- Using v-if to conditionally render the title -->
                        <strong>Send Quote on Mail</strong>
                    </h5>
                    <button class="btn-back pl-4 py-2 border-0" @click="dontSendToCustomer()">
                        <img src="/images/close-logo.svg" class="close_icon" />
                    </button>
                </div>
                <div class="modal-body">
                    <input type="text" class="form-control text-left mb-3" id="full-name" placeholder="Enter Full Name"
                        v-model="quoteFullName" ref="nameRef" />
                    <input type="text" class="form-control text-left" id="email" placeholder="Enter Email"
                        v-model="quoteSendingEmails" @keyup="checkEmail($event)" />
                        <p class="error-text"> {{ emailErrorMsg }} </p>
                        <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12">
                                <button class="commonButton mt-3" :disabled="!isValidQuotesEmail" @click="sendQuotesToCustomer()">Send Quote</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            quoteFullName: '',
            quoteSendingEmails: '',
            isValidQuotesEmail:false,
            emailErrorMsg:'',
        }
    },

    methods: {
        checkEmail(event) {
      console.log('check email called: ', event.target.value);
      let emailStr = event.target.value;
      const EMAIL = "^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@"+ "[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$"
      let checkEmail = emailStr.match(EMAIL);
      if(checkEmail==null) {
        this.emailErrorMsg = "Please Enter Valid Email";
        this.isValidQuotesEmail = false;
      } else {
        this.emailErrorMsg = "";
        this.isValidQuotesEmail = true;
      }
    },
        sendQuotesToCustomer() {
            let qData = {
                "distributor": {
        "distributorID": "CITPL",
        "agentID": "",
        "agentType": "",
        "salesChannel": "",
        "channelType": "B2B",
        "varFields": [
            {
                "fieldName": "",
                "fieldValue": ""
            }
        ]
    },
    "eventName": "sendSQ",
    "application": "INS",
    "data": [
        {
            "fullName": this.quoteFullName,
            "gender": "Male",
            "pin": "411014",
            "emailId":this.quoteSendingEmails,
            "channel": "email",
            "distributorEmail": "no-reply@coversure.in",
            "insuranceCompany": "CHIL",
            "planFor": "1 Year",
            "sumInsured": "₹10,000,000.00",
            "netPremium": "₹11,345.76",
            "tax": "₹2,042.24",
            "finalPremium": "₹13,388.00",
            "addOns": [
                {
                    "Road Side Assistance": "250",
                    "Compulsory Personal Accident": "15,000",
                    "IMT": "15,000",
                    "NCB Protection": "15,000"
                }
            ],
            "imageArray":[
                {
                    "Accidents":"https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/Accident+%282%29.png",
                    "Injury or death":"https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/Accident+%281%29.png",
                    "Theft":"https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/Accident+%282%29.png",
                    "Man-made disasters":"https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/Accident+%283%29.png",
                    "Fire":"https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/Accident+%281%29.png",
                    "Natural disasters":"https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/Accident+%284%29.png",
                    "Property damage":"https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/Accident+%285%29.png"
                }
            ],
            "productName": "Test"
        }
    ]
}
        var headers = {
          // "quoteId":this.uiQuoteId,
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
            this.backendApi("sendEmailandSMS", qData,headers)
                .then((response) => {
                    //console.log('save quote response: ', response);
                    if (response == 'error') {
                        //   self.apiError = true;
                    } else if (response && response.data) {

                        console.log('email send success: ', response.data)
                    }
                })
                .catch((error) => {
                    console.log('error msg block email', error);
                });
            this.$emit('sendQuoteModalClose')
        },
        dontSendToCustomer(){
            this.$emit('sendQuoteModalClose')
        }
    }
}
</script>
<style lang="scss">
@import "../assets/scss/components/modals.scss";

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    z-index: 10;
}

.modal-dialog-breakup {
    position: relative;
    background-color: #FFF;
    max-width: 480px;
    min-height: 220px;
    max-height: fit-content;
    overflow-y: auto;
min-width: 400px;
    border-radius: 12px;
    padding: 48px 12px 12px;
}

#endclose {
    position: relative;
    left: 0;
}
.btn-back{
        background-color: transparent !important;
        color: $cardBackgroundDark !important;
    }
.dark .modal-dialog-breakup{
    background: $header-dark-bg !important;
    *{
        color: $heading-darkColor !important;
    }
    .close_icon{
        filter: invert(1);
    }
    .btn-back{
        background-color: transparent !important;
        color: $cardBackgroundDark !important;
    }
}
</style>