<template>
  <div style="margin-bottom:7rem; margin-top:5rem">
    <InnerHeader/>
    <div class="row justify-content-center mt-5" >
      <div class="col-12 col-lg-8 col-md-8 col-xl-8">
        <div class="row confirmpayBG" >
           <div class="col-md-6">
            <ProductDetailsHeader/>
            <div class="row mt-3">
              <div class="col-md-6 mb-2">
                <router-link to="/process-wizard">
                  <button class="backbutton">Back</button>
                </router-link>  
              </div>
              <div class="col-md-6 mb-2">
                <!-- <router-link to="/complete-quote"> -->
                 
                  <button @click="submitproposal()" class="btn commonButton mb-2">Submit</button>
                <!-- </router-link> -->
              </div>
            </div>
            <div  class="d-none">
              <div class="headingcards mt-3" v-for="item in items" :key="item.heading">
              <h3>{{ item.heading }}</h3>
              <img class="confirmimage" :src="this.quoteResponseDetails && this.quoteResponseDetails.quote_response_data.supportingData.insCompanyLogo" alt="Bank Logo"  />
              <div class="d-flex align-items-center mt-3 justify-content-between">
                <p>{{ item.planType }}</p>
                <h4>{{ this.quoteResponseDetails && this.quoteResponseDetails.quote_response_data.insuranceAndProducts.policyType}}</h4>
              </div>
              <div class="d-flex align-items-center justify-content-between" v-if="this.quoteResponseDetails?.quote_response_data.insuranceAndProducts.policyType!=='StandaloneTP(1)'">
                <p>{{ item.coverType }}</p>
                <h4>{{ this.quoteResponseDetails && currency(this.quoteResponseDetails.quote_response_data.supportingData.insuredDeclaredValue.suggestedIDV) }}</h4>
              </div>
              <hr />
              <div class="d-flex align-items-center gap-1 specifications" v-for=" usp in this.extractUSP(this.quoteResponseDetails?.quote_response_data?.supportingData)"
              :key="usp">
              <img src="../../assets/images/checksvg.svg" width="10px" />
                 
                <p>{{ usp}}</p>
              </div>
              
              <hr />
              <div class="d-flex align-items-center mt-3 justify-content-between">
                <p>{{ item.premiumAmount }}</p>
                <h4>{{this.quoteResponseDetails &&  currency(this.quoteResponseDetails.quote_response_data.productDetails.totalPremiumDetails[0].totalPremiumValueBeforeTax) }}</h4>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <p>{{ item.GSTType }}</p>
                <h4>{{ this.quoteResponseDetails &&  currency(this.quoteResponseDetails.quote_response_data.productDetails.totalPremiumDetails[0].totalTax)}}</h4>
              </div>
              <hr />
              <div class="d-flex align-items-center justify-content-between">
                <h4>{{ item.amountTitle }}</h4>
                <h6>{{ this.quoteResponseDetails &&  currency(this.quoteResponseDetails.quote_response_data.productDetails.totalPremiumDetails[0].totalPremiumWithTax) }}</h6>
              </div>
              <div class="text-end">
                <!-- <a href="#" style="font-size: 12px;text-decoration: underline;" data-bs-toggle="modal" data-bs-target="#amountBreakup">Show Breakup</a> -->
                 <span @click="showBreakupmodal()" class="text-primary" style="font-size: 12px;text-decoration: underline;">Premium Breakup</span>
              </div>
            </div>
            
            <p v-for="(usp, index) in usps" :key="index">usp{{ usp }}</p>
            <div class="row mt-3">
              <div class="col-md-6 mb-2">
                <router-link to="/process-wizard">
                  <button class="backbutton">Back</button>
                </router-link>
              </div>
              <div class="col-md-6 mb-2">
                <!-- <router-link to="/complete-quote"> -->
                 
                  <button @click="submitproposal()" class="btn commonButton mb-2">Submit</button>
                <!-- </router-link> -->
              </div>
            </div>
            </div>
            <div>
              <p class="my-1 errorMessage" v-for="error in displayApiErrorsOnUI" :key="error">{{error}}</p>
            </div>
          </div>
          <div class="col-md-6 formobileview">
              

            <div class="formWizard  position-sticky" style="top: 5rem">
              <h3 class="processheading">
                Confirm & Proceed
              </h3>

              <!-- <article class="FilterAccordian headingcards" v-if="this.structuredJSON && this.structuredJSON.addOns">
                <header class="d-flex align-items-center" :class="{'border-bottom pb-2': this.structuredJSON.addOns.expanded}"  @click="this.structuredJSON.addOns.expanded = !this.structuredJSON.addOns.expanded">
                  <button class="btn p-0">
                    <img src="../../assets/images/minus.svg" class="hide-show-icon"   v-show="this.structuredJSON.addOns.expanded"/>
                    <img src="../../assets/images/plus.svg" class="hide-show-icon"   v-show="!this.structuredJSON.addOns.expanded"/>
                  </button>
                  <h6 class="question-title mb-0 mx-0">Add Ons</h6>
                </header>
                <div>
                  <transition name="fade" mode="out-in">
                    <div v-if="this.structuredJSON &&  this.structuredJSON.addOns.expanded">
                      <div class="d-flex gap-2 addonplan align-items-center pb-2" v-for="(addons, index) in this.addontoShow" :key="addons.optionSelected"  :class="{'my-3': index != this.addontoShow.length-1}" v-show="this.addontoShow.length>0">
                        <input class="form-check-input" type="checkbox" :value="addons.optionDescription" id="flexCheckDefault" checked disabled>
                        <label><h5 class="mb-0">{{  addons.optionDescription }}</h5></label>
                      </div>
                      <p  v-if="this.addontoShow?.length===0" class="pt-2 pb-1 d-block text-danger">No Addons Selected</p>
                    </div>
                  </transition>
                </div>
              </article> -->

              <template  v-if="this.structuredJSON">
              <article class="FilterAccordian headingcards mt-3" v-for="details in  filteredStructureJson" :key="details.id">
                <header class="d-flex justify-content-between align-items-center" :class="{'border-bottom pb-2': details.expanded}" @click="details.expanded = !details.expanded">
                  <div class="d-flex align-items-center">
                    <button class="btn p-0">
                      <img src="../../assets/images/minus.svg"  class="hide-show-icon"  v-show="details.expanded"/>
                      <img src="../../assets/images/plus.svg" class="hide-show-icon"  v-show="!details.expanded"/>
                    </button>
                    <h6 class="question-title mb-0">
                      {{ details.label }}
                    </h6>
                  </div>
                  <button class="edit-btn btn py-1 px-2" @click="editProcess($event, details.name)">
                    <img src="../../assets/images/edit.svg"  class="edit_icon"/>
                  </button>
                </header>

                <transition name="fade"  mode="out-in">
                  <div  v-if="details.expanded">
                    <template v-if="details.fieldGroups">
                      <div class="fieldgrp"  v-for="fieldFirst in Object.values(details.fieldGroups)" :key="fieldFirst.id">
                        <h6 class="headinglabels" v-if="displaySubHeading(fieldFirst)">{{ fieldFirst.label }}</h6>
                        <template v-if="fieldFirst.fields">
                          <div v-for="fieldSecond in Object.values(fieldFirst.fields)" :key="fieldSecond.id">
                            <div class="content mt-3">
                              <div class="d-flex justify-content-between mb-2" v-if="fieldSecond.input !=''" style="gap:20px">
                                <p>{{ fieldSecond.label }}</p>
                                <h5 v-if="fieldSecond.type=='date'" class="mb-0" >{{ formatDateToMonthString(fieldSecond.input)}}</h5>
                                <h5 v-else class="mb-0" >{{ (fieldSecond.input !='' && fieldSecond.input =='0') ? 'No' : (fieldSecond.input !='' && fieldSecond.input =='1') ? 'Yes' : capitalizeFirstLetter(fieldSecond.input,fieldSecond.label) }}</h5>
                               
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="fieldFirst.fieldGroups">
                          <div v-for="fieldSecond in Object.values(fieldFirst.fieldGroups)" :key="fieldSecond.id">
                            <div class="content mt-3">
                              <div class="d-flex justify-content-between mb-2"  v-if="fieldSecond.input !=''" style="gap:20px">
                                <p>{{ fieldSecond.label }}</p>
                                <h5 v-if="fieldSecond.type=='date'" class="mb-0" >{{ formatDateToMonthString(fieldSecond.input)}}</h5>
                                <h5 v-else class="mb-0" >{{ (fieldSecond.input !='' && fieldSecond.input =='0') ? 'No' : (fieldSecond.input !='' && fieldSecond.input =='1') ? 'Yes' : capitalizeFirstLetter(fieldSecond.input,fieldSecond.label) }}</h5>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </template>
                    <template v-if="details.fields">
                      <div  class="fieldgrp"  v-for="fieldFirst in Object.values(details.fields)" :key="fieldFirst.id">
                            <div class="content mt-3">
                              <div class="d-flex justify-content-between mb-2" v-if="fieldFirst.input !=''" style="gap:20px">
                                <p>{{ fieldFirst.label }}</p>
                                <h5 class="mb-0">{{ (fieldFirst.input !='' && fieldFirst.input =='0') ? 'No' : (fieldFirst.input !='' && fieldFirst.input =='1') ? 'Yes' :  capitalizeFirstLetter(fieldFirst.input,fieldFirst.label) }}</h5>
                              </div>
                            </div>
                      </div>
                    </template>
                  </div>
              </transition>
              </article>
            </template>

            </div>
          </div>
          <!--  -->
         
        </div>
      </div>
    </div>



  </div>
  <VarificationModal />
  <AmountBreakupSq v-if="this.openAmountBreakupflag" @emitClose="emitClose()"/>
  <pageLoader v-if="loadingState" :msg="loadingMessage"/>
  <Verifypremium v-if="Verifypremiumflag" @emitVerifyClose="VerifymodalClose" @emitcontinueNewPremium="continueNewPremium"/>
</template>

<script>
import VarificationModal from "@/modals/varificationModal.vue";
import InnerHeader from "../header/innerHeader.vue";
import pageLoader from "../pageLoaders/pageLoader.vue";
import AmountBreakupSq from "@/modals/amountBreakupSq.vue";
import ProductDetailsHeader from "../productDetailsHeader/ProductDetailsHeader.vue";
import { getDistributer} from '@/mixins/common';
import Verifypremium from "@/modals/verifypremium.vue";
export default {
  mixins: [getDistributer],
  components: {
    VarificationModal,
    InnerHeader ,
    pageLoader,
    AmountBreakupSq,
    ProductDetailsHeader,
    Verifypremium
  },
  methods: {
    VerifymodalClose(){
     this.Verifypremiumflag=false;

    },
    continueNewPremium(){
      this.Verifypremiumflag=false;
      this.updateProposalStatus(11);
    },
    compareValues(dataArray, dataObject) {
      console.log("array",dataArray,dataObject)
    for (const item of dataArray) {
      const proposalValue = this.getValueFromPath(dataObject, item.proposalPath);
      const singleQuoteValue = this.getValueFromPath(dataObject, item.singleQuotePath);

      if (proposalValue !== singleQuoteValue) {
        console.log("proposalValue ", proposalValue, singleQuoteValue);
        this.Sqflag = true;
        break;
      }
    }
    console.log("sqflag",this.Sqflag)
  },
   getValueFromPath(object, path) {
    return path.split('.').reduce((acc, key) => acc && acc[key], object);
},
    getCkycDetails() {
      this.loadingState = true;
      this.loadingMsg="Fetching CKYC Details"
      var self = this;
      // this.loading = true;
      this.kycRedirectUrl = "";
      this.msg = [];

      let reqData = {
        "personalInformation": {
          "dateOfBirth": this.proposalDetails.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input,
          "email": "",
          "mobileNumber":this.proposalDetails.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input ,
          "zipCode": this.proposalDetails.proposal_json.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.zipCode.input,
          "pan": this.PANNumber,
          //Gender For Testing
          "gender":"male"
        },
        "product": {
          "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,
          "productId": this.selectedPlan.insuranceAndProducts.productCode,
        },
        "trackInfo": {
          "manufacturerQuoteID": this.uniqueQuoteId.toString(),
          "manuApplicationNo":"" ,
          "KYCRequestID": ""
        },
        "distributor": {
          "distributorID":this.distributorID,
          "agentID": "agentId",
          "salesChannel": "online",
          "channelType": "B2B"
        },
        "kycInfo": {
          "ReturnURL": "https://www.1silvebullet.tech"  
        },
        "journey_id": this.journey_id,
      };

      // Save request json  
      //sessionStorage.setItem("request_json", JSON.stringify(reqData));
      let headers = {"Authorization":`Bearer ${ this.$store.state.token}`};
      console.log('calling kyc...')
      this.backendApi("getCkyc", reqData, headers)
        .then((response) => {
          this.loadingState=false;
          
          this.kycResponse = response?.data?.data;
          this.kycStatus = this.kycResponse?.kycInfo?.Status;

          if(response.data.errors.length > 0) {
            for(let i=0; i<response.data.errors.length; i++)
            {
              this.msg.push('Verify that your PAN-Date of Birth combination is correct and try again. If the issue continues, please contact the support staff.');
            } 
          } else if(this.kycStatus == "approved") {
            this.loadingState = false;
            if(this.newsqData.productDetails.totalPremiumDetails[0].totalPremiumWithTax!==this.quoteResponseDetails.quote_response_data.productDetails.totalPremiumDetails[0].totalPremiumWithTax){
              this.Verifypremiumflag=true;
            }
            else{
              this.updateProposalStatus(11);
            }
            // this.$router.push({ path: '/process-wizard' })
            sessionStorage.setItem("kyc_response", JSON.stringify(this.kycResponse));
            // let userData = JSON.parse(sessionStorage.getItem("userData")); 
            // setTimeout(function() { 
            //   self.$router.push("/process-wizard");
            // }, 2000);            
          } else if(response.data.data.kycInfo.Status == null && response.data.data.kycInfo.RedirectURL == null) {
            
            // this.getCkycDetails2();

          } else if((this.kycStatus == "Pending" || this.kycStatus == "POAIncomplete" || this.kycStatus == "Incomplete")) {
            this.isUploadDocRequired = true;
          } else  {
            sessionStorage.removeItem('kyc_response');
            this.kycRedirectUrl = this.kycResponse.kycInfo.RedirectURL;
            setTimeout(function() { 
              window.open(self.kycRedirectUrl, '_blank').focus();
            }, 2000);
          }

        })
        .catch((error) => {
          console.log('error msg block : get kyc', error);
          self.loading = false;
        }); 
    },
    getSingleQuote() {
      // var self = this;
      // self.loading2 = true;
      this.loadingState = true;
      this.loadingMessage = "Fetching Sq Details..."
      console.log("this.requestBody", this.apiReqData)
      sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
      this.backendApi("quotationApi", this.apiReqData, { "Authorization": `Bearer ${this.$store.state.token}` }, "post")
        .then((response) => {
          console.log("inside response sq")
          if (response.data.errors.length > 0) {
            // this.loading = false;
            this.displayApiErrorsOnUI = []
            this.loadingState = false;
      
            const msg = [];
            for (let i = 0; i < response.data.errors.length; i++) {
              msg.push(response.data.errors[i]["errorDisplayMessage"])
            }
            console.log("Errors in Response", msg);
            this.displayApiErrorsOnUI = msg;
            // this.$router.push({ path: '/error-page', query: { backUrl: 'policy-quote', msg: msg } });
          } else if (response && response.data) {
            
            this.requestId = response.data.reqId;
            // this.loading = false;
            if (response?.data?.data?.quote?.length > 0) {
              console.log("inside loop for")
              this.responseData.push(response.data.data.quote)
            }
            if (response.data.errors.length == 0) {
              const self = this;
setTimeout(function () {
  self.pollapiflag = true;
  self.pollData();
}, 1000);

            }
            else {
              console.log("inside else case")
              this.loadingState = false;
              // this.loading2 = false;
              if (this.responseData.length == 0) {
                this.erMsg = 'No products found as per your selected filter. Please change the filters.';
                // self.beforeDestroy();
              }
            }
          }

        }).catch((error) => {
          console.log('error msg block dp', error);
          const msg = "Service is Unavailable, Please try again later.";
          this.displayApiErrorsOnUI.push(msg);
          // this.$router.push({ path: '/error-page', query: { backUrl: 'policy-quote', msg: msg } });
        })
    },
    async getMotorProduct() {
      // this.loading2 = true;
      this.loadingState = true;
      const startTime = Date.now();
      const maxPollingTime = 3 * 60 * 1000;
      let headers = { "Authorization": `Bearer ${this.$store.state.token}` };
      let reqData = [this.requestId];

      while (this.pollapiflag && (Date.now() - startTime) < maxPollingTime) {
        try {
          // if(this.$route.path !== "/policy-details"){
          //   this.pollapiflag = false;
          //   break;
          // }
          const response = await this.backendApi("quotationPoll", reqData, headers, "get");
          if (response.data.errors.length > 0) {
            this.loading = false;
            this.loadingState = false;
            const msg = [];
            for (let i = 0; i < response.data.errors.length; i++) {
              msg.push(response.data.errors[i]["errorDisplayMessage"])
            }
            this.displayApiErrorsOnUI = msg;
            // this.$router.push({ path: '/error-page', query: { backUrl: 'policy-quote', msg: msg } });
          }
          if (response?.data?.data?.quote?.length > 0) {
            // this.loading2 = false;
            this.loadingState = false;
            this.suggestedIDV = response?.data?.data?.quote[0].supportingData.insuredDeclaredValue.suggestedIDV;
            this.responseData = response.data.data.quote;
            sessionStorage.setItem("quoteSingleData", JSON.stringify(this.responseData))
          }
          if (response.data.data.isPollComplete == true) {
            this.loadingState = false;
            this.pollapiflag = false;
            this.uniqueQuoteId=response.data.data.quote[0].trackInfo.manufacturerQuoteID;
            this.newsqData=response.data.data.quote[0];
            sessionStorage.setItem("updated_premium",JSON.stringify(this.newsqData));
            this.getCkycDetails();
            this.loading2 = false;
            // this.beforeDestroy();
            break;
          }
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].errorIdentifier == "INVALID_REQUEST_ID") {
              this.pollapiflag = true;
            }
          }
          await this.sleep(1000);
        }catch(err){
          console.log('error msg block', err);
          this.loading = false;
        }
      };
      if ((Date.now() - startTime) >= maxPollingTime) {
        this.pollapiflag = false;
        this.loading2 = false;
        this.loading = false;
      }
    },
    pollData() {
      console.log("polling")
        if (this.pollapiflag == true) {
          this.getMotorProduct();
        } else {
          // this.loading2 = false;
          if (this.responseData?.length == 0) {
            this.erMsg = 'No products found as per your selected filter. Please change the filters.';
          }
          // this.beforeDestroy();
        }
    },
    editProcess(e, name){
      e.stopPropagation(); 
      sessionStorage.setItem(
        "currentTab",
        JSON.stringify(name)
      );
      this.$router.push({ path: '/process-wizard',  query: { process: name }})
    },
    showBreakupmodal(){
      console.log("clicked-------");
      this.openAmountBreakupflag=true;
    },
    extractUSP(obj) {
      const uspArray = [];
      for (let key in obj) {
        if (key.startsWith('usp') && obj[key] && obj[key] !== null && obj[key] !== undefined) {
          uspArray.push(obj[key]);
        }
      }
      return uspArray;
    },
    capitalizeFirstLetter(input,input2) {
  
      if(input2=="Date of Birth" || input2=="Policy Expiry Date" || input2=="Previous Policy Expiry Date"){
        const date = new Date(input);
      if (isNaN(date.getTime())) return input; // Return original if invalid date

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
       }
      if (typeof input !== 'string' || input === '') return input;
      return input.charAt(0).toUpperCase() + input.slice(1);
     
    },
    currency(amt) {
      const tmpAmt = parseFloat(amt).toFixed(2);
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    submitproposal(){
      if(this.Sqflag){
        this.getSingleQuote()
      }
      else{
      this.updateProposalStatus(11);   
      }
        
    },
    updateProposalStatus(statusid)
    {
        let qData = {
          "journey_id":this.journey_id,
          "status_id": statusid
          };
        var headers = {
          // "quoteId":this.uiQuoteId,
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
          };

          this.loadingState = true;
          this.loadingMessage = "Updating Proposal Status"
        
        this.backendApi("ProposalStatus", qData, headers)
          .then((response) => {
            this.loadingState = false;
            if(response == 'error') {
              self.apiError = true;
              
            }
           
             else if(response && response.data) {
              console.log('proposal status updated: ', response.data);
              if(statusid == 11 || statusid == 13)
              {
                if(response.data.status == "failed")
                {
                  let msg=['We are facing some issue while sending an e-mail']
                  this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check', msg: msg }});
                }
                else
                {
                  this.$router.push('/thank-you-RM');
                }
              }
            }
          })
          .catch((error) => {
            this.loadingState = false;
            console.log('error msg block email', error);
            
          });
    },
    emitClose(){
      this.openAmountBreakupflag = false;
    },
    getSPDetailsOneSB(man_code ) {
      let reqData = [man_code];
      var headers ={ "Authorization": `Bearer ${this.$store.state.token}` };
      this.loadingState = true;
      this.loadingMessage = "Fetching SP Details"
      this.backendApi("getSPDetailsOneSB", reqData, headers, "get")
      .then((response) => {
        this.loadingState = false;
        if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data) {
          console.log('response sp details: ', response.data);
          if(response.data.status == "failed")
          {
            //redirection to error page and on go back button redirect to homepage
          }
          sessionStorage.setItem("sp_details_data", JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        this.loadingState = false;
        console.log('error msg block spdetailOneSB API', error);
        this.displayApiErrorsOnUI.push(msg);
        // this.$router.push({ path: '/error-page', query: {msg: "SP details not Available" }});

      });
    },
    //get ProposalDetails
    getProposalById(journey_id) {
      let qData = [this.journey_id,"Proposal Review"];
      var headers ={ "Authorization": `Bearer ${this.$store.state.token}` };
      this.loadingState = true;
      this.loadingMessage = "Fetching Proposal Details..."
      this.backendApi("getProposalDetails", qData, headers, "get")
      .then((response) => {
          this.loadingState = false;
          this.proposalDetails = response.data.data.proposal;
          // console.log("",this.SQ_CALL_BEFORE_PROPOSAL);
          // const insuranceCompanyCode=response.data.data.quote_response.quote_response_data.insuranceAndProducts.insuranceCompanyCode;
          // console.log("productcode",insuranceCompanyCode)
          // var rsgiArray;
          // if(this.SQ_CALL_BEFORE_PROPOSAL){
            // rsgiArray= this.SQ_CALL_BEFORE_PROPOSAL[insuranceCompanyCode];
          // }
          
          if(this.PROPOSAL_TO_SINGLEQUOTE_FIELD_MAPPING){
            this.compareValues(this.PROPOSAL_TO_SINGLEQUOTE_FIELD_MAPPING,response.data)
          }
          if(response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input){
        this.apiReqData.personalInformation.individualDetails[0].firstName=response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input
      }
      if(response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input){
        this.apiReqData.personalInformation.individualDetails[0].middleName=response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.middleName.input
      }
      if(response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.lastName.input){
        this.apiReqData.personalInformation.individualDetails[0].lastName=response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.lastName.input
      }
      if(response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input){
        this.apiReqData.personalInformation.individualDetails[0].gender=response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input
      }
      if(response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input){
        this.apiReqData.personalInformation.individualDetails[0].dateOfBirth=response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input
      }
      if(response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.email.input){
        this.apiReqData.personalInformation.individualDetails[0].email=response.data.data.proposal.proposal_json.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.email.input
      }
      // 
      if(response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyEndDate.input){
        this.apiReqData.product.previousPolicyDetails.previousPolicyEndDate=response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyEndDate.input
      }
      if(response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyInsurer.input){
        this.apiReqData.product.previousPolicyDetails.previousPolicyInsurer=response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyInsurer.input
      }
      if(response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyNumber.input){
        this.apiReqData.product.previousPolicyDetails.previousPolicyNumber=response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyNumber.input
      }
      if(response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyEndDate.input){
        this.apiReqData.product.previousPolicyDetails.previousPolicyOdExpiryDate=response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyEndDate.input
      }

      if(response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyInsurer.input){
        this.apiReqData.product.previousPolicyDetails.previousPolicyInsurer=response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyInsurer.input
      }

      if(response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyNumber.input){
        this.apiReqData.product.previousPolicyDetails.previousPolicyOdNumber=response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyNumber.input
      }
      if(response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyEndDate.input){
        this.apiReqData.product.previousPolicyDetails.previousPolicyTpExpiryDate=response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyEndDate.input
      }
      if(response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyInsurer.input){
        this.apiReqData.product.previousPolicyDetails.previousPolicyTpInsurer=response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyInsurer.input
      }
      if(response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyNumber.input){
        this.apiReqData.product.previousPolicyDetails.previousPolicyTpNumber=response.data.data.proposal.proposal_json.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyNumber.input
      }
      
      
        
          sessionStorage.setItem(
            "filledData",
            JSON.stringify(this.proposalDetails.proposal_json)
          );
          sessionStorage.setItem(
            "proposal_ui_id",
            JSON.stringify(this.proposalDetails.proposal_ui_id)
          );
          //call sp details api 
          let spdetail_flag= process.env.VUE_APP_SPDETAIL_ONESB;
          this.restructureJson();
          if(spdetail_flag == 1)
          {
            if (sessionStorage.getItem("rm_data")){
              this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
              if( this.rmData['RoleId']!=="1"){
                this.getSPDetailsOneSB( this.proposalDetails.insurance_company_code);
              }
            }
          }
          this.quoteDetails = response.data.data.quote_request;
          this.userDetails = response.data.data.user;

          let userData=JSON.parse(sessionStorage.getItem("userData"))
          if(!userData){
            this.PANNumber=this.userDetails.PANNumber;
          }else{
            this.PANNumber=userData.PANNumber;
          }
          
          
          sessionStorage.setItem(
            "user_profile",
            JSON.stringify(this.userDetails)
          );
          sessionStorage.setItem(
            "quote_request",
            JSON.stringify(this.quoteDetails.quote_request_json)
          );
          
          this.quoteResponseDetails = response.data.data.quote_response;
          
          let pln = this.quoteResponseDetails.quote_response_data;
          sessionStorage.setItem("selected_plan_data", JSON.stringify(pln));
          this.addontoShow=pln.productDetails.motorCovers;
          sessionStorage.setItem(
            "selectedPlanForCheckout",
            JSON.stringify(pln)
          );
          sessionStorage.setItem(
            "journey_id",
            JSON.stringify(journey_id)
          );
          this.$store.commit("setJourneyId", journey_id);
          if (this.proposalDetails.tag_name) {
            sessionStorage.setItem(
              "filledDataTab",
              JSON.stringify(this.proposalDetails.tag_name)
            );
            sessionStorage.setItem(
              "currentTab",
              JSON.stringify(this.proposalDetails.tag_name)
            );
          }
        }) 
        .catch((error) => {
          this.loadingState = false;
          console.log("error msg block dp", error);
        });
    },
    restructureJson(){
        let addOnsObject = {
          expanded: false,
          data: this.quoteResponseDetails?.quote_response_data.productDetails.motorCovers,
          addOnSelected: []
        }
        this.structuredJSON.addOns = addOnsObject;
        const otherValues = Object.values(this.proposalDetails.proposal_json.fieldGroups)
  .filter(requiredFields => requiredFields.fieldGroups !== undefined)
  .map((item,index) => {
      return {
        ...item,
        expanded: index==0 ? true : false
      };
    });
    this.structuredJSON.otherRequiredFields = otherValues;

    // document.querySelectorAll('.form-check-input').forEach(element => element.setAttribute('checked', true));
    },
    formatDateToMonthString(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
    },
    displaySubHeading(data){
      let displayFlag = false;
      if (data.fields) {
          for (let key in data.fields) {
            if (data.fields[key].input && data.fields[key].input !== "") {
              displayFlag = true;
            }
          }
        }
        if (data.fieldGroups) {
          for (let key in data.fieldGroups) {
            if (data.fieldGroups[key].input && data.fieldGroups[key].input.trim() !== "") {
              displayFlag = true;
            }
          }
        }
        return displayFlag;
    }
  },
  mounted() {
    this.distributorID = getDistributer()["distributerID"];
    // this.selected_plan_data = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    // this.SQ_CALL_BEFORE_PROPOSAL=this.$store.state.configData.SQ_CALL_BEFORE_PROPOSAL;
    this.PROPOSAL_TO_SINGLEQUOTE_FIELD_MAPPING=this.$store.state.configData.PROPOSAL_TO_SINGLEQUOTE_FIELD_MAPPING
    this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"));
    // // this.setupData();
    this.selectedPlan=JSON.parse(sessionStorage.getItem("selected_plan_data"));
    this.journey_id = JSON.parse(sessionStorage.getItem("journey_id"));
    this.addontoShow=this.selectedPlan.productDetails.motorCovers;
    this.loadingState = true;
    this.getProposalById(this.journey_id);
    console.log("this.journey_id",this.journey_id);
  },
  data() {
    return {
      SQ_CALL_BEFORE_PROPOSAL:'',
      Sqflag:false,
      Verifypremiumflag:false,
      newsqData:'',
      distributorID:'',
      uniqueQuoteId:"",
      selectedPlan:'',
      pollapiflag:false,
      loadingState: false,
      loadingMessage: "",
      structuredJSON:{},
      userDetails:{},
      quoteDetails:{},
      proposalDetails:{},
      journey_id:"",
      responseData: [],
      addontoShow:[],
      openAmountBreakupflag:false,
      quoteResponseDetails:null,
      PANNumber:'',
      PROPOSAL_TO_SINGLEQUOTE_FIELD_MAPPING:'',
      displayApiErrorsOnUI: [],
      items: [
        {
          rmData: null,
          selected_plan_data: null,
          quote_request: null,
          heading: "Plan Summary",
          BankLogo: "$SBI",
          planType: "Policy Type",
          plan: "₹ 14,560",
          coverType: "IDV Cover",
          cover: "₹13,36,452",
          nestedItems: [
            {
              checkitems: "Zero Paper Claims",
            },
            {
              checkitems: "Spot Claims Upto Rs. 20k",
              carcover: "₹ 14,560",
            },
            {
              checkitems: "28 Cashless Garages",
            },
            {
              checkitems: "Self-Video Claims",
            },
          ],
          premiumAmount: "Premium Amount",
          amount: "₹ 14,560",
          GSTType: "GST @18%",
          GST: "₹ 2620",
          amountTitle: "Total Amount",
          totalAmount: "₹ 17,180",
        },
      ],
      Addon: [
        {
          checkheadings: "Zero Paper Claims",
        },
        {
          checkheadings: "Spot Claims Upto Rs. 20k",
        },
        {
          checkheadings: "28 Cashless Garages",
        },
        {
          checkheadings: "Self-Video Claims",
        },
      ],
      Additions: [
        {
          id: 1,
          accoheading: "Owner Details",

          expanded: true,
          nestedItems: [
            {

              checkContent: "Akash Chopra",
              subcheck: "Name",
            },
            {
              
              checkContent: "+91 8888 38888",
              subcheck: "Mobile",
            },
            {
              
              checkContent: "16/04/1986",
              subcheck: "Date of Birth",
            },
            {
              
              checkContent: "AUK37878K",
              subcheck: "PAN",
            },
            {
              
              checkContent: "Male",
              subcheck: "Gender",
            },
            {
              
              addressContent: "328/330, Mangaldas Market, opposite Jumma Masjid, Mumbai, Maharashtra 400002, India",
              address: "Address",
            },
           
          ],
        },
        {
            id: 2,
          accoheading: "Vehicle Details",

          expanded: true,
          nestedItems: [
            {
              
              checkContent: "1887787377",
              subcheck: "Engine Number",
            },
            {
              
              checkContent: "299898798788",
              subcheck: "Chassis Number",
            },
            {
              
              checkContent: "Yes",
              subcheck: "Is your vehicle taken on loan?",
            },
            {
              
              checkContent: "SBI Auto Loan",
              subcheck: "Financier",
            },
           
           
          ],
        },
        {
            id: 3,
          accoheading: "Previous Policy Details",

          expanded: true,
          nestedItems: [
            
            {
                subcontent:"Own Damage Detail",
              checkContent: "SBI",
              subcheck: "Insurer",
            },
            {
              
              checkContent: "12/06/2025",
              subcheck: "Policy Expiry Date",
            },
            {
              
              checkContent: "99389898",
              subcheck: "Policy Number",
            },
        
          
           
          ],
        },
        
      ],
    };
  },
  computed: {
    resolveImagePath() {
      return (variable) => {
        // Map the SCSS variable name to its actual value
        const variableMap = {
          $SBI: "/images/state-bank-of-india-logo.svg", // Match this to your SCSS variable definition
        };
        return variableMap[variable] || "";
      };
    },
    filteredStructureJson(){
      const checkInputValues = (obj) => {
        if (obj.fields) {
          for (let key in obj.fields) {
            if (obj.fields[key].input && obj.fields[key].input.trim() !== "") {
              return true;
            }
          }
        }
        if (obj.fieldGroups) {
          for (let key in obj.fieldGroups) {
            if (checkInputValues(obj.fieldGroups[key])) {
              return true;
            }
          }
        }
        return false;
      };
      return this.structuredJSON.otherRequiredFields && this.structuredJSON.otherRequiredFields.filter(container => checkInputValues(container));
    }    
  },
 
 
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/components/select-card.scss";
@import "../../assets/scss/components/multi-quotes.scss";
@import "../../assets/scss/components/form-wizard.scss";

.errorMessage {
  color: rgb(255, 94, 94);
  font-size: 14px;
}
.fade-enter-active,
.fade-leave-active{
  transition: opacity 0.3s ease-in-out;
}
.fade-enter-from, .fade-leave-to{
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>
