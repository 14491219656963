<template lang="">
  <template v-if="!loadingState">
    <innerHeader/>
    <div class="commonmargin">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-md-10 col-xl-10" >
          <div class="wizard-hold">
            <div class="row" >
              <div class="col-md-6">
              <productDetailsHeader/>
            </div>
            <div class="col-12 col-lg-6 col-md-6 col-xl-6 text-center kycform">
              <img src="/images/kyc.gif" />
              <h1 class="headings my-3">Complete Your KYC</h1>
              <div class="row justify-content-center">
                <div class="col-12 col-lg-12 col-md-12 col-xl-12">
                  <div class="card">
                    <label class="headinglabel" >PAN Number <span style="color:#f00">*</span></label>
                    <input type="text" class="form-control p-2" @input="doUppercase()" placeholder="PAN Number" v-model="this.panNumber"/>
                    <span class="text-danger errormsg">{{errorMsg["pan_number"]}}</span>
                    <label class="headinglabel  mt-3" >Date of Birth <span style="color:#f00">*</span></label>
                    <div  class="position-relative">
                      <input type="date" class="form-control  p-2" placeholder="date" v-model="this.dateOfBirth" @input="doUppercase()"/>
                    <img src="/images/date.svg" class="downarrowimage">
                    </div>
                    <span class="text-danger errormsg">{{ errorMsg["dob"] }}</span>
                    <button class="commonButton mt-3" @click="fetchKyc()">Fetch KYC</button>
                    <button class="backbutton mt-2" @click="revisitSingleQuote()">Back</button>
                    <p class="errorClass" v-if="this.kycStatus=='Incomplete'">Your CKYC status is Incomplete. <router-link to="/upload-ovds">Click Here</router-link> to upload Officially Valid Documents (OVDs).</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <pageLoader :msg="loadingMsg" />
  </template>
</template>
<script>
import innerHeader from "@/components/header/innerHeader.vue";
import pageLoader from "@/components/pageLoaders/pageLoader.vue";
import { getDistributer } from "@/mixins/common";
import productDetailsHeader from "../productDetailsHeader/ProductDetailsHeader.vue";

export default {
  components: {
    innerHeader,
    pageLoader,
    productDetailsHeader,
  },
  mixins: [getDistributer],
  data() {
    return {
      // userData:{},
      distributorID: "",
      loadingState: false,
      loadingMsg: "",
      msg: [],
      panNumber: "",
      dateOfBirth: "",
      quoteResponseId: "",
      kycResponse: "",
      kycStatus: "",
      kycRedirectUrl: "",
      errorMsg: [],
      journey_id: sessionStorage.getItem("journey_id")
        ? JSON.parse(sessionStorage.getItem("journey_id"))
        : "",
      selectedPlan: {},
    };
  },
  methods: {
    goBack() {
      this.$router.push("policy-details");
    },
    doUppercase() {
      this.panNumber = this.panNumber.toUpperCase();
      let userType = JSON.parse(sessionStorage.getItem("user-type"));
      const fourthChar = this.panNumber.charAt(3);
      const currentDate = new Date();
      const minDate = new Date();
      minDate.setFullYear(currentDate.getFullYear() - 18);
      const userDob = new Date(this.dateOfBirth);
      console.log(userType, fourthChar, minDate, userDob);
      
      if (userType && fourthChar === "C" && userType=='corporate') {
        if (userDob > minDate) {
          this.errorMsg["pan_number"] = "For corporate PAN, the date should be at least 18 years old."
          return false;
        }else{
          this.errorMsg["pan_number"] = ""
          return false;
        }
      }
    },
    fetchKyc() {
      console.log(
        "fetchKyc called: ",
        this.panNumber,
        "DOB: ",
        this.dateOfBirth
      );
      this.errorMsg = [];
      const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

      if (!this.panNumber) {
        this.errorMsg["pan_number"] = "Please enter PAN card number";
      } else if (!this.dateOfBirth) {
        this.errorMsg["dob"] = "Please enter your date of birth";
      } else {
        // Validate PAN card number
        if (regpan.test(this.panNumber)) {
          // Check age
          const dob = new Date(this.dateOfBirth);
          const today = new Date();
          let age = today.getFullYear() - dob.getFullYear();
          const monthDifference = today.getMonth() - dob.getMonth();

          // Adjust age if the birthday hasn't occurred yet this year
          if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < dob.getDate())
          ) {
            age--;
          }

          if (age < 18) {
            this.errorMsg["dob"] =
              "For CKYC Proposer age to be greater than 18 year";
          } else {
            // Call API if age is valid
            let userData = JSON.parse(sessionStorage.getItem("userData"));
            userData.PANNumber = this.panNumber;
            sessionStorage.setItem("userData", JSON.stringify(userData));
            this.getCkycDetails();
            // this.$router.push("proposer-details");
          }
        } else {
          // Invalid PAN card number
          this.errorMsg["pan_number"] = "Invalid PAN card number";
        }
      }
    },
    revisitSingleQuote() {
      this.$router.push({ path: "/policy-quote" });
    },
    //getKyc Data
    getCkycDetails() {
      this.loadingState = true;
      this.loadingMsg = "Fetching CKYC Details";
      var self = this;
      // this.loading = true;
      this.kycRedirectUrl = "";
      this.msg = [];

      let reqData = {
        personalInformation: {
          dateOfBirth: this.dateOfBirth,
          email: "",
          mobileNumber: this.userData.mobileNumber,
          zipCode: this.userData.pinCode,
          pan: this.panNumber,
          //Gender For Testing
          gender: "male",
        },
        product: {
          insuranceCompanyCode:
            this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,
          productId: this.selectedPlan.insuranceAndProducts.productCode,
        },
        trackInfo: {
          manufacturerQuoteID: this.singleQuote.trackInfo.manufacturerQuoteID,
          manuApplicationNo: "",
          KYCRequestID: "",
        },
        distributor: {
          distributorID: this.distributorID,
          agentID: "agentId",
          salesChannel: "online",
          channelType: "B2B",
        },
        kycInfo: {
          ReturnURL: "https://www.1silvebullet.tech",
        },
        journey_id: this.journey_id,
      };

      // Save request json
      //sessionStorage.setItem("request_json", JSON.stringify(reqData));
      let headers = { Authorization: `Bearer ${this.$store.state.token}` };
      console.log("calling kyc...");
      this.backendApi("getCkyc", reqData, headers)
        .then((response) => {
          this.loadingState = false;

          this.kycResponse = response?.data?.data;
          this.kycStatus = this.kycResponse?.kycInfo?.Status;

          if (response.data.errors.length > 0) {
            for (let i = 0; i < response.data.errors.length; i++) {
              this.msg.push(
                "Verify that your PAN-Date of Birth combination is correct and try again. If the issue continues, please contact the support staff."
              );
            }
          } else if (this.kycStatus == "approved") {
            this.$router.push({ path: "/process-wizard" });
            sessionStorage.setItem(
              "kyc_response",
              JSON.stringify(this.kycResponse)
            );
            // let userData = JSON.parse(sessionStorage.getItem("userData"));
            // setTimeout(function() {
            //   self.$router.push("/process-wizard");
            // }, 2000);
          } else if (
            response.data.data.kycInfo.Status == null &&
            response.data.data.kycInfo.RedirectURL == null
          ) {
            // this.getCkycDetails2();
          } else if (
            this.kycStatus == "Pending" ||
            this.kycStatus == "POAIncomplete" ||
            this.kycStatus == "Incomplete"
          ) {
            this.isUploadDocRequired = true;
          } else {
            sessionStorage.removeItem("kyc_response");
            this.kycRedirectUrl = this.kycResponse.kycInfo.RedirectURL;
            setTimeout(function () {
              window.open(self.kycRedirectUrl, "_blank").focus();
            }, 2000);
          }
        })
        .catch((error) => {
          console.log("error msg block : get kyc", error);
          self.loading = false;
        });
    },
  },
  watch: {
    dateOfBirth(newDate) {
      const dateParts = newDate.split("-");
      if (dateParts[0] && dateParts[0].length > 4) {
        dateParts[0] = dateParts[0].slice(0, 4);
        this.dateOfBirth = dateParts.join("-");
      }
    },
  },
  mounted() {
    this.distributorID = getDistributer()["distributerID"];
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"));
    this.selectedPlan = JSON.parse(
      sessionStorage.getItem("selected_plan_data")
    );
    this.singleQuote = JSON.parse(sessionStorage.getItem("quoteSingleData"));
    // this.getCkycDetails();
    // this.setupData();
    if (sessionStorage.getItem("filledData")) {
      sessionStorage.removeItem("filledData");
    }
    const isPreFilled = getDistributer()["isPreFilled"];
    if (this.$store.state.customerData && isPreFilled) {
      const customerData = this.$store.state.customerData;
      this.panNumber = customerData.kycDetails.panNumber;
      this.dateOfBirth = customerData.personalInformation.dateOfBirth;
    }
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/process-wizard.scss";
@import "../../assets/scss/components/Kyc.scss";

.errorClass {
  font-size: 12px;
  color: #ff5b5b;
  line-height: 155%;
  margin-top: 0.8rem;
  text-align: left;
  a {
    color: #3b39d9;
    padding-bottom: 0.1rem;
    cursor: pointer;
    border-bottom: 1px solid #3b39d9;
  }
}

</style>
