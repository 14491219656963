<template>
    <div class="modal-overlay breakupmodal">
        <div class="modal-dialog-breakup p-3" role="document">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <h5 class="modal-title text-align-center d-flex w-100 justify-content-between align-items-center">

                        <h2>Premium Breakup</h2>
                        <span @click="emitClose()">
                            <img src="/images/close-logo.svg" class="close_icon" />
                        </span>
                    </h5>
                </div>
                <div class="modal-body mt-2">
                    <!-- base cover -->
                    <h3 style="font-size: 14px; text-align: left;">Base Cover</h3>
                    <div class="d-flex justify-content-between align-items-center mb-2 "
                        v-if="isValidInput(selectedPlanData?.productDetails?.basicOwnDamagePremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicODPremiumValue)">
                        <div class="d-flex justify-content-between w-100 gap-2">
                            <p class="text-left" style="text-align: left;">Basic Own Damage Premium</p>
                            <h6> ₹ {{
                                currency(selectedPlanData?.productDetails?.basicOwnDamagePremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicODPremiumValue)
                                }} </h6>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-2 "
                        v-if="isValidInput(selectedPlanData?.productDetails?.thirdPartyPremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicTPPremiumValue)">
                        <div class="d-flex justify-content-between w-100 gap-2">
                            <p class="text-left" style="text-align: left;">Basic Third Party Premium</p>
                            <h6>₹ {{
                                currency(selectedPlanData?.productDetails?.thirdPartyPremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicTPPremiumValue)
                                }}</h6>
                        </div>
                    </div>
                    <div v-for="addons in this.filteredBasicAddon" :key="addons.optionSelected">
                        <div class="d-flex justify-content-between w-100 gap-2 mb-2 ">
                            <p class="text-left" style="text-align: left;">{{ addons.optionDescription }}</p>
                            <h6>₹ {{
                                currency(showPrimium(addons.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue,addons.premiumIncludedFlag))
                                }}</h6>
                        </div>
                    </div>
                    <hr>
                    <!-- Addons-->
                    <h3 style="font-size: 14px; text-align: left;" v-if="this.filteredAddonsbreakup.length > 0">
                        Addons</h3>
                    <div class="">
                        <div v-for="addons in this.filteredAddonsbreakup" :key="addons.optionSelected">
                            <div class="d-flex justify-content-between w-100 gap-2 mb-2 ">
                                <p class="text-left" style="text-align: left;">{{ addons.optionDescription }}</p>
                                <h6>₹ {{
                                    currency(showPrimium(addons.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue,addons.premiumIncludedFlag))
                                    }}</h6>
                            </div>
                        </div>
                        <hr v-if="this.filteredAddonsbreakup.length > 0">
                        <!-- Accessories  -->
                    <h3 style="font-size: 14px; text-align: left;" v-if="this.filteredAccessories.length > 0">
                        Accessories</h3>
                    
                        <div v-for="addons in this.filteredAccessories" :key="addons.optionSelected">
                            <div class="d-flex justify-content-between w-100 gap-2 mb-2 ">
                                <p class="text-left" style="text-align: left;">{{ addons.optionDescription }}</p>
                                 <h6 v-if="showPrimium(addons.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue,addons.premiumIncludedFlag)> 0"> ₹
                                    {{ currency(showPrimium(addons.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue,addons.premiumIncludedFlag)) }}
                                 </h6>
                                 <h6 v-else>Included in Plan</h6>
                            </div>
                        </div>
                        <hr v-if="this.filteredAccessories.length > 0">
                        <!-- discount -->
                        <h3 style="font-size: 14px; text-align: left;"  v-if="selectedPlanData?.productDetails?.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue || (applicableDiscount.length > 0 )" >Discounts</h3>
                        <div class="d-flex justify-content-between w-100 mb-2 "
                            v-if="this.selectedPlanData?.productDetails?.motorNCBPremium[0]?.premiumForEachPolicyTerm[0]?.premiumForEachPPO[0]?.premiumForEachPPF[0]?.totalPremiumValue">
                            <p class="text-left" style="text-align: left;">No-Claim Bonus ({{String(this.selectedPlanData.productDetails.motorNCBPremium[0].nextNcbSlabDetails[0].nextNcbSlabApplicable).trim()}}%)
                            </p>
                            <h6>- ₹
                                {{ currency(selectedPlanData?.productDetails?.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue) }}
                            </h6>
                        </div>
                        
                        <div v-for="(discount, index) in applicableDiscount" :key="index" class="d-flex justify-content-between w-100  gap-2 mb-2"
                                v-show="discount !=undefined && isValidInput(discount.premium)">
                                <p class="text-left" style="text-align: left;">{{ discount.addonName.optionName }} Discount</p>
                                <p></p>
                                <h6 v-if="currency(discount?.premium)> 0">- ₹
                                    {{ currency((discount?.premium)) }}
                                </h6>
                                <h6 v-else>Included in Plan</h6>
                            </div>

                        <hr v-if="this.selectedPlanData?.productDetails?.motorNCBPremium[0]?.premiumForEachPolicyTerm[0]?.premiumForEachPPO[0]?.premiumForEachPPF[0].totalPremiumValue || (this.applicableDiscount.length > 0 )">

                        <!-- Premium Details -->
                        <h3 style="font-size: 14px; text-align: left;">Premium
                            Details</h3>
                        <div class="d-flex justify-content-between w-100 gap-2 mb-2 "
                            >
                            <p class="text-left" style="text-align: left; ">Package Premium</p>
                            <h6>₹ {{
                                currency(this.totalPremiumWithoutTax-this.totalDiscount)
                                }}</h6>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 mb-2 "
                            >
                            <p class="text-left" style="text-align: left; ">GST@18%</p>
                            <h6>₹ {{ currency(this.totalTax) }}
                            </h6>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 mb-2 "
                           >
                            <p class="text-left" style="text-align: left; ">Final Premium (GST inc.)</p>
                            <h6>₹
                                {{ currency(this.totalPremiumWithTax) }}
                            </h6>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn commonButton" @click="emitClose()">Close</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getPremiumBreakup} from '../mixins/common';
export default {
    props: ['selectedPlanData','plansWithAddons'],
    data() {
        return {
            userData:"",
            filteredaddon: [],
            // processedAddons:[],
            filteredBasicAddon: [],
            filteredAddonsbreakup: [],
            filteredAccessories:[],
            Automobile_Association: [],
            allDiscounts: [],
            applicableDiscount:[],
            totalPremiumWithoutTax:0,
            totalTax:0,
            totalPremiumWithTax:0,
            totalDiscount:0
        }
    },
    mounted() {
        console.log('this.selectedPlanData')
        console.log("plansWithAddons",this.plansWithAddons)
        if(sessionStorage.getItem("userData")){
            this.userData = JSON.parse(sessionStorage.getItem("userData"))
        }
        this.calculatePremium();
        // if (sessionStorage.getItem("quoteSingleData")) {
            // this.selectedPlanData = JSON.parse(sessionStorage.getItem("quoteSingleData"))   
            console.log("filtered Addons PRemium filteredAddon",this.filteredaddon)

            this.filteredaddon = this.selectedPlanData.productDetails.motorCovers.filter(option => {
        const isAllConditionsMet = option.inbuiltInPlan === "No" &&
        option.mandatoryInProduct === "No" &&
        option.premiumForEachPolicyTerm.every(term =>
            term.premiumForEachPPO.every(ppo =>
                ppo.premiumForEachPPF.every(ppf =>
                    ppf.totalPremiumValue === 0 || ppf.totalPremiumValue === null
                )
            )
        ) &&
        option.premiumIncludedFlag === "No";

            this.isAddonInPlans = this.plansWithAddons.some(plan =>
                plan.addons.some(planAddon =>
                    planAddon.addonName.optionSelected === option.optionSelected
                )
            );

            return !isAllConditionsMet && this.isAddonInPlans;
        });
         console.log("isAddonInPlans",this.isAddonInPlans)
        // }
        this.filteredBasicAddon = this.filteredaddon
            .filter((addon) => getPremiumBreakup().basic_cover.includes(addon.optionSelected))
            .sort((a, b) => {
                const aIndex = getPremiumBreakup().basic_cover.indexOf(a.optionSelected);
                const bIndex = getPremiumBreakup().basic_cover.indexOf(b.optionSelected);
                return aIndex - bIndex;
            });

        this.filteredAddonsbreakup = this.filteredaddon.filter((addon) =>
            getPremiumBreakup().Addons.includes(addon.optionSelected)).sort((a, b) => {
                const aIndex = getPremiumBreakup().Addons.indexOf(a.optionSelected);
                const bIndex = getPremiumBreakup().Addons.indexOf(b.optionSelected);
                return aIndex - bIndex;
            });

        this.filteredAccessories= this.filteredaddon.filter((addon) =>
            getPremiumBreakup().Accessories.includes(addon.optionSelected)).sort((a, b) => {
                const aIndex = getPremiumBreakup().Accessories.indexOf(a.optionSelected);
                const bIndex = getPremiumBreakup().Accessories.indexOf(b.optionSelected);
                return aIndex - bIndex;
            });

            this.allDiscounts = this.filteredaddon.filter((addon) =>
                ["volDed", "autoasso", "antiThft", "payAsDrv"].includes(addon.optionSelected)
            );

            this.plansWithAddons.forEach(plan => {
                // Ensure only plans with matching productCode are checked
                if (plan.productCode === this.selectedPlanData.insuranceAndProducts.productCode) {
                    
                    this.allDiscounts.forEach(discount => {
                        const foundAddon = plan.addons.find(
                            addon => addon.addonName.optionSelected === discount.optionSelected
                        );

                        console.log("Checking:", discount.optionSelected, "against", plan.addons.map(a => a.addonName.optionSelected));

                        if (foundAddon) {
                            console.log("Found Matching Addon:", foundAddon.addonName);
                            this.applicableDiscount.push(foundAddon);
                        }
                    });
                }
            });
            
        
        console.log("filtered Addons PRemium filteredAddon after",this.allDiscounts,this.applicableDiscount)

    },

    methods: {
        filteredMatchingAddons() {
            return this.filteredAddon.filter(addon =>
            this.plansWithAddons.some(plan =>
                plan.addons.some(planAddon =>
                planAddon.addonName.optionSelected === addon.optionSelected
                )
            )
            );
        },
        calculatePremium() {
            // Reset totals before calculation
            this.totalPremiumWithoutTax = 0;
            this.totalTax = 0;
            this.totalPremiumWithTax = 0;

            let totalDiscountPremiumWithoutTax = 0; // Store premium for excluded add-ons
            let totalDiscountTax = 0; // Store tax for excluded add-ons

            const excludedAddons = ["volDed", "autoasso", "antiThft","payAsDrv"];

            // Add basic own damage and third-party premiums to totals
            console.log("adding basic");
            this.totalPremiumWithoutTax = 
            Number(this.selectedPlanData.productDetails.basicOwnDamagePremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicODPremiumValue) +
            Number(this.selectedPlanData.productDetails.thirdPartyPremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicTPPremiumValue);

            this.totalTax = 
            Number(this.selectedPlanData.productDetails.basicOwnDamagePremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalTax) +
            Number(this.selectedPlanData.productDetails.thirdPartyPremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalTax);
            console.log("tax before adding addons tax",this.totalTax);

            if(this.plansWithAddons.length){
            // Loop through each motor cover
             this.selectedPlanData.productDetails.motorCovers.forEach(motorCover => {
                const optionSelected = motorCover.optionSelected;

                // Find the matching add-on
                const matchingAddon = this.plansWithAddons?.find(product => 
                product.addons?.some(addon => addon.addonName?.optionSelected === optionSelected)
                )?.addons.find(addon => addon.addonName?.optionSelected === optionSelected);

                if (matchingAddon) {
                    console.log("inside addons matchied",matchingAddon)
                motorCover.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF.forEach(ppf => {
                    if (motorCover.optionSelected == optionSelected) {
                        if (excludedAddons.includes(optionSelected)) {
                            console.log("insdie excluded addons calculation",ppf.premiumValue,"ppf at 0",ppf.tax[0].taxValue,"ppf",ppf )
                            // Store excluded add-on separately
                            totalDiscountPremiumWithoutTax += Number(ppf.premiumValue || 0);
                            totalDiscountTax += Number(ppf.tax[0].taxValue || 0);
                            console.log("Excluded Addon Premium:", totalDiscountPremiumWithoutTax);
                            console.log("Excluded Addon Tax:", totalDiscountTax,this.totalPremiumWithoutTax);
                        } else {
                            // Regular premium calculation
                            this.totalPremiumWithoutTax += Number(ppf.premiumValue || 0);
                            this.totalTax += Number(ppf.tax[0].taxValue || 0);
                            console.log("Matching Premium Without Tax:", this.totalPremiumWithoutTax);
                            console.log("Matching Tax:", this.totalTax,ppf.tax);
                        }
                    }
                });
            }
        });
    }
    //adding ncb discount to totalDiscount
            this.totalPremiumWithoutTax -= totalDiscountTax;
            this.totalDiscount = totalDiscountPremiumWithoutTax  + Number(this.selectedPlanData.productDetails.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue)
            // Calculate total premium with tax and subtract excluded add-ons
            this.totalPremiumWithTax = (this.totalPremiumWithoutTax + this.totalTax) - (this.totalDiscount);
            console.log("Final Total Premium With Tax:", this.totalPremiumWithTax);
        },

        showPrimium(amount, premiumIncludedFlag) {
            if (premiumIncludedFlag === "Yes") {
                return "Included"
            }
            else {
                return amount
            }
        },
        emitClose() {
            this.$emit('emitClose')
        },
        isValidInput(input) {
            const num = Number(input);

            if (!isNaN(num) && num !== 0 && num !== 0.00) {
                return true;
            }
            return false;
        },
        currency(amt) {
            const tmpAmt = parseFloat(amt).toFixed(2);
            return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }



}
</script>
<style lang="scss">
@import "../assets/scss/components/modals.scss";

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    z-index: 10;
}

.modal-dialog-breakup {
    position: relative;
    background-color: #FFF;
    max-width: 370px;
    height: 480px;
    overflow-y: auto;
    min-width: 400px;
    border-radius: 12px;
    padding: 48px 12px 12px;
}

#endclose {
    position: relative;
    left: 0;
}

.dark .modal-dialog-breakup {
    background: $header-dark-bg !important;

    * {
        color: $heading-darkColor !important;
    }

    .close_icon {
        filter: invert(1);
    }
}
</style>