<template>
    <div class="modal-overlay breakupmodal">
        <div class="modal-dialog-breakup p-3" role="document">

            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <h5 class="modal-title text-align-center d-flex w-100 justify-content-between align-items-center">

                        <h2>Premium Breakup</h2>
                        <span @click="emitClose()">
                            <img src="/images/close-logo.svg" class="close_icon" />
                        </span>
                    </h5>
                </div>
                <div class="modal-body mt-2">
                   <!-- base cover -->
                   <h3 style="font-size: 14px; text-align: left;">Base Cover</h3>
                    <div class="d-flex justify-content-between align-items-center mb-2 "
                        v-if="isValidInput(selectedPlanData?.productDetails?.basicOwnDamagePremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicODPremiumValue)">
                        <div class="d-flex justify-content-between w-100 gap-2 ">
                            <p class="text-left" style="text-align: left;">Basic Own Damage Premium</p>
                            <h6>₹ {{
                                currency(selectedPlanData?.productDetails?.basicOwnDamagePremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicODPremiumValue)
                                }} </h6>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-2 "
                        v-if="isValidInput(selectedPlanData?.productDetails?.thirdPartyPremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicTPPremiumValue)">
                        <div class="d-flex justify-content-between w-100 gap-2">
                            <p class="text-left" style="text-align: left;">Basic Third Party Premium</p>
                            <h6>₹ {{
                                currency(selectedPlanData?.productDetails?.thirdPartyPremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicTPPremiumValue)
                                }}</h6>
                        </div>
                    </div>
                    <div v-for="addons in this.filteredBasicAddon" :key="addons.optionSelected">
                        <div class="d-flex justify-content-between w-100 gap-2 mb-2 ">
                            <p class="text-left" style="text-align: left;">{{ addons.optionDescription }}</p>
                            <h6>₹ {{
                                showPrimium(addons.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue,addons.premiumIncludedFlag)
                                }}</h6>
                        </div>
                    </div>
                    <hr>
                    <!-- Addons-->
                    <h3 style="font-size: 14px; text-align: left;" v-if="this.filteredAddonsbreakup.length > 0">
                        Addons</h3>
                    <div class="">
                        <div v-for="addons in this.filteredAddonsbreakup" :key="addons.optionSelected">
                            <div class="d-flex justify-content-between w-100 gap-2 mb-2 ">
                                <p class="text-left" style="text-align: left;">{{ addons.optionDescription }}</p>
                                <h6>₹ {{
                                    showPrimium(addons.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue,addons.premiumIncludedFlag)
                                    }}</h6>
                            </div>
                        </div>
                        <hr  v-if="this.filteredAddonsbreakup.length > 0" >
                          <!-- Accessories  -->
                     <h3 style="font-size: 14px; text-align: left;" v-if="this.filteredAccessories.length > 0">
                        Accessories</h3>
                    
                        <div v-for="addons in this.filteredAccessories" :key="addons.optionSelected">
                            <div class="d-flex justify-content-between w-100 gap-2 mb-2 ">
                                <p class="text-left" style="text-align: left;">{{ addons.optionDescription }}</p>
                                <h6>₹ {{
                                    showPrimium(addons.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue,addons.premiumIncludedFlag)
                                    }}</h6>
                            </div>
                        </div>
                        <hr v-if="this.filteredAccessories.length > 0">
                        
                         <!-- discount -->
                         <h3 style="font-size: 14px; text-align: left;" v-if="selectedPlanData?.productDetails?.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue || (Voluntary_Deductible.length > 0 && isValidInput(Voluntary_Deductible[0].premiumForEachPolicyTerm[0]?.premiumForEachPPO[0]?.premiumForEachPPF[0]?.premiumValue) )">Discounts</h3>
                        <div class="d-flex justify-content-between w-100 gap-2 mb-2 "
                            v-if="selectedPlanData?.productDetails?.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue">
                            <p class="text-left" style="text-align: left;">No-Claim Bonus ({{String(this.selectedPlanData.productDetails.motorNCBPremium[0].nextNcbSlabDetails[0].nextNcbSlabApplicable).trim()}}%)
                            </p>
                            <h6>- ₹
                                {{ currency(selectedPlanData?.productDetails?.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue) }}
                            </h6>
                        </div>

                        <div v-for="(discount, index) in applicableDiscount" :key="index" class="d-flex justify-content-between w-100 gap-2 mb-2"
                            v-show="discount && isValidInput(discount.premiumForEachPolicyTerm?.[0]?.premiumForEachPPO?.[0]?.premiumForEachPPF?.[0]?.premiumValue)">
                            
                            <p class="text-left" style="text-align: left;">{{ discount.optionDescription }} Discount</p>
                            
                            <p></p>
                            
                            <h6 v-if="currency(discount?.premiumForEachPolicyTerm?.[0]?.premiumForEachPPO?.[0]?.premiumForEachPPF?.[0]?.premiumValue) > 0">
                                - ₹ {{ currency(discount?.premiumForEachPolicyTerm?.[0]?.premiumForEachPPO?.[0]?.premiumForEachPPF?.[0]?.premiumValue) }}
                            </h6>
                            
                            <h6 v-else>Included in Plan</h6>
                        </div>

                        <hr v-if="selectedPlanData?.productDetails?.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue || (Voluntary_Deductible.length > 0 && isValidInput(Voluntary_Deductible[0].premiumForEachPolicyTerm[0]?.premiumForEachPPO[0]?.premiumForEachPPF[0]?.premiumValue))  ">
                         <!-- Premium Details -->
                        <h3 style="font-size: 14px; text-align: left;">Premium
                            Details</h3>
                        <div class="d-flex justify-content-between w-100 gap-2 mb-2 "
                            v-if="isValidInput(selectedPlanData?.productDetails?.totalPremiumDetails[0].totalPremiumValueBeforeTax)">
                            <p class="text-left" style="text-align: left; ">Package Premium</p>
                            <h6>₹ {{
                                currency(selectedPlanData?.productDetails?.totalPremiumDetails[0].totalPremiumValueBeforeTax)
                                }}</h6>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 mb-2 "
                            v-if="isValidInput(selectedPlanData?.productDetails?.totalPremiumDetails[0].totalTax)">
                            <p class="text-left" style="text-align: left; ">GST@18%</p>
                            <h6>₹ {{ currency(selectedPlanData?.productDetails?.totalPremiumDetails[0].totalTax) }}
                            </h6>
                        </div>
                        <div class="d-flex justify-content-between w-100 gap-2 mb-2 "
                            v-if="isValidInput(selectedPlanData?.productDetails?.totalPremiumDetails[0].totalPremiumWithTax)">
                            <p class="text-left" style="text-align: left; ">Final Premium (GST inc.)</p>
                            <h6>₹
                                {{ currency(selectedPlanData?.productDetails?.totalPremiumDetails[0].totalPremiumWithTax) }}
                            </h6>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn commonButton" @click="emitClose()">Close</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getPremiumBreakup} from '../mixins/common';
export default {
    data() {
        return {
            applicableDiscount:[],
            userData:"",
            selectedPlanData: '',
            filteredaddon: [],
            // processedAddons:[]
            filteredBasicAddon: [],
            filteredAddonsbreakup: [],
            filteredAccessories:[],
            Automobile_Association: [],
            Voluntary_Deductible: [],
            Premium_breakup: {
                basic_cover: ['tptd', 'cngCvr', 'paCvr', 'paUnCvr', 'paPdCvr',],
                Addons: ['elCvr', 'nonElCvr', 'cngCvr', 'zeroDep', 'rsa', 'engPrt', 'conExp', 'keyPrt', 'lossPerl']
            },
        }
    },
    mounted() {
        if(sessionStorage.getItem("userData")){
            this.userData = JSON.parse(sessionStorage.getItem("userData"))
        }
        if (sessionStorage.getItem("selected_plan_data")) {
            this.selectedPlanData = JSON.parse(sessionStorage.getItem("selected_plan_data"))
            this.filteredaddon = this.selectedPlanData.productDetails.motorCovers.filter(option => {
                const isAllConditionsMet = option.inbuiltInPlan === "No" &&
                    option.mandatoryInProduct === "No" &&
                    option.premiumForEachPolicyTerm.every(term =>
                        term.premiumForEachPPO.every(ppo =>
                            ppo.premiumForEachPPF.every(ppf =>
                                ppf.totalPremiumValue === 0 || ppf.totalPremiumValue === null
                            )
                        )
                    ) &&
                    option.premiumIncludedFlag === "No";
                // Return true if not all conditions are met (i.e., we want to keep this option)
                return !isAllConditionsMet;
            });
        }
        this.filteredBasicAddon = this.filteredaddon
  .filter((addon) => getPremiumBreakup().basic_cover.includes(addon.optionSelected))
  .sort((a, b) => {
    const aIndex = getPremiumBreakup().basic_cover.indexOf(a.optionSelected);
    const bIndex = getPremiumBreakup().basic_cover.indexOf(b.optionSelected);
    return aIndex - bIndex;
  });
  this.filteredAddonsbreakup = this.filteredaddon.filter((addon) =>
        getPremiumBreakup().Addons.includes(addon.optionSelected)).sort((a, b) => {
    const aIndex = getPremiumBreakup().Addons.indexOf(a.optionSelected);
    const bIndex = getPremiumBreakup().Addons.indexOf(b.optionSelected);
    return aIndex - bIndex;
  });
  this.filteredAccessories= this.filteredaddon.filter((addon) =>
        getPremiumBreakup().Accessories.includes(addon.optionSelected)).sort((a, b) => {
    const aIndex = getPremiumBreakup().Accessories.indexOf(a.optionSelected);
    const bIndex = getPremiumBreakup().Accessories.indexOf(b.optionSelected);
    return aIndex - bIndex;
  });
        this.allDiscounts = this.filteredaddon.filter((addon) =>
            ["volDed", "autoasso", "antiThft", "payAsDrv"].includes(addon.optionSelected)
        );

        this.applicableDiscount = this.allDiscounts.filter(discount =>
            this.selectedPlanData.productDetails.motorCovers.some(cover => cover.optionSelected === discount.optionSelected)
        );
        console.log(this.applicableDiscount,">>>>>>>>>>")

    },


    methods: {
        showPrimium(amount, premiumIncludedFlag) {
            if (premiumIncludedFlag === "Yes") {
                return "Included"
            }
            else {
                return amount
            }

        },
        emitClose() {
            this.$emit('emitClose')
        },
        isValidInput(input) {
            const num = Number(input);

            if (!isNaN(num) && num !== 0) {
                return true;
            }
            return false;
        },
        currency(amt) {
            const tmpAmt = parseFloat(amt).toFixed(2);
            return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }



}
</script>
<style lang="scss">
@import "../assets/scss/components/modals.scss";

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    z-index: 10;
}

.modal-dialog-breakup {
    position: relative;
    background-color: #FFF;
    max-width: 370px;
    height: 480px;
    overflow-y: auto;
    min-width: 400px;
    border-radius: 12px;
    padding: 48px 12px 12px;
}

.dark .modal-dialog-breakup {
    background: $header-dark-bg !important;

    * {
        color: $heading-darkColor !important;
    }

    .close_icon {
        filter: invert(1);
    }
}
</style>