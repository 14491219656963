<template>
        <div class="otp_container-backdrop">
                <div class="otp_container text-center p-4 bg-light">
                        <h4 class="otpheading">OTP Verification</h4>
                        <p>Please enter the OTP sent to your phone number to complete your verification.</p>
                        <div class="otp_numbers mt-4 mb-2 border p-3">
                                <div class="otp_inputs d-flex align-items-bottom justify-content-center">
                                        <input :ref="el => (refs[otp.id] = el)" 
                                                v-for="(otp, index) in state.otpNumbers"
                                                :key="index" :id="'otp-' + index" 
                                                v-model="otp.value"
                                                @input="onInput($event, index)" 
                                                @keydown="onKeyDown($event, index)"
                                                @paste="onPaste($event, index)" 
                                                maxlength="1" 
                                                class="otp-input"
                                                type="number" 
                                                autocomplete="off" />
                                </div>
                        </div>
                        <p class="expiryErrorMessage"
                                :class="{ 'expiryError': state.errorOtpMessage}"
                                v-if="state.errorOtpMessage && hideMessage"
                                >
                                {{ state.errorOtpMessage }}
                        </p> 
                        <p class="expiryErrorMessage"
                                :class="{ 'expirySuccess': state.OtpExpiryMessage?.type === 'success'}"
                                v-if="state.OtpExpiryMessage?.msg && hideMessage">
                                {{ state.OtpExpiryMessage.msg }}
                        </p> 
                        <p class="expiryErrorMessage"
                                :class="{ 'expirySuccess': state.OtpExpiryErrorMessage?.type === 'success'}"
                                v-if="state.OtpExpiryErrorMessage?.msg"
                                >
                                {{ state.OtpExpiryErrorMessage.msg }}
                        </p> 
                        <p v-if="time">Resend OTP in {{ time }} sec</p>
                        <p class="mt-3" v-else>Didn't get the code? <span class="resend"
                                        @click="resendOtp($event)">Resend</span></p>
                        <button @click="validateOTP()" class="btn commonButton mt-4" :disabled="disableVerify">Verify &
                                Submit</button>
                        <button class="btn backbutton " @click="cancleOtpVerify">Cancel</button>
                </div>
                <PageLoader v-if="state.loadingState" :msg="state.loadingMessage"/>
        </div>
</template>
<script setup>
import { ref, reactive, onMounted, defineEmits, defineProps, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import backendApi from '@/apis/axios-backend';
import PageLoader from '../pageLoaders/pageLoader.vue';
import { getDistributer} from '@/mixins/common';
// Define emits
const emit = defineEmits(['close-modal', "resend-otp", "set-timer"]);
const props = defineProps({
  time: {
    type: Number,
    required: true
  }
})


// Setup function
const store = useStore();
const router = useRouter();
const refs = ref({});
const localTime = ref(props.time);
let hideMessage = ref(true);



// Reactive state
// Reactive state
const state = reactive({
        distributorID:'',
        loadingState: false,
        loadingMessage: "",
        hideError: false,
        OtpExpiryMessage: "",
        errorOtpMessage: "",
        OtpExpiryErrorMessage: null,
        otpExpiryTime: "",
        journeyId: "",
        otpNumbers: Array.from({ length: 6 }, (_, id) => ({ id, value: "" })),
});


onMounted(() => {
        state.distributorID = getDistributer()["distributerID"];
        refs.value[0]?.focus();
        state.journeyId = JSON.parse(sessionStorage.getItem("journey_id"));
        state.OtpExpiryMessage = "";
        state.OtpExpiryErrorMessage = JSON.parse(sessionStorage.getItem("OtpExpiryErrorMessage"));
        emit("set-timer")
});

// Methods
const onInput = (event, index) => {
        const inputValue = event.target.value;

        if (inputValue.length > 0) {
                // Remove any non-digit characters
                const digits = inputValue.replace(/\D/g, '');

                // Update the OTP numbers array with the digits
                digits.split('').forEach((digit, i) => {
                        if (index + i < state.otpNumbers.length) {
                                state.otpNumbers[index + i].value = digit;
                        }
                });

                // Focus the next input if there are remaining digits
                const nextIndex = index + digits.length;
                if (nextIndex < state.otpNumbers.length) {
                        const nextInput = refs.value[nextIndex];
                        nextInput?.focus();
                }
        } else {
                // If input is cleared, move focus to the previous input
                if (index > 0) {
                        const prevInput = refs.value[index - 1];
                        prevInput?.focus();
                }
        }
};


const onKeyDown = (event, index) => {
        if (event.key === 'Backspace') {
                if (!state.otpNumbers[index].value && index > 0) {
                        // If the current field is empty, move focus to the previous field
                        const prevInput = refs.value[index - 1];
                        prevInput?.focus();
                }
        } else if (event.key === 'ArrowLeft') {
                // Move focus to the previous input on left arrow key
                if (index > 0) {
                        const prevInput = refs.value[index - 1];
                        prevInput?.focus();
                }
        } else if (event.key === 'ArrowRight') {
                // Move focus to the next input on right arrow key
                if (index < state.otpNumbers.length - 1) {
                        const nextInput = refs.value[index + 1];
                        nextInput?.focus();
                }
        }
};

const onPaste = (event, index) => {
        event.preventDefault();
};


const resendOtp = (e) => {
        e.preventDefault();
        emit("set-timer");
        refs.value[0]?.focus();
        hideMessage.value = true;
        const expiryTimeOfOtp = sessionStorage.getItem("ExpiryTime");
        const now = new Date();

        let expiryDate = null;
        if (expiryTimeOfOtp) {
                expiryDate = new Date(expiryTimeOfOtp);
        }
        emit("resend-otp");
        sessionStorage.setItem("OtpExpiryMessage", JSON.stringify({
                type: "success",
                msg: "OTP sent to your mobile"
        }));

        state.OtpExpiryMessage = JSON.parse(sessionStorage.getItem("OtpExpiryMessage"));
        state.otpNumbers.forEach(otp => {
                otp.value = "";
        });
        refs.value[0]?.focus();

        setTimeout(() => {
                hideMessage.value = false;
                state.OtpExpiryMessage = "";
        }, 3000);
};


const validateOTP = () => {
        let companyCode = JSON.parse(sessionStorage.getItem('selected_plan_data'))
        const otpTransactionId = JSON.parse(sessionStorage.getItem('otpId'));
        const reqData = {
                distributor: {
                        distributorID: state.distributorID,
                        channelType: "B2B"
                },
                insuranceCompanyCode: companyCode.insuranceAndProducts.insuranceCompanyCode,
                UITrackingRefNo: "279887d3-0370-4ad0-aab6-328135af94f2",
                OTPDetails: {
                        OTP: state.otpNumbers.map(otp => otp.value).join(''),
                        OTPTransactionId: otpTransactionId
                }
        };
        const headers = { "Authorization": `Bearer ${store.state.token}`};

        state.loadingState = true
        state.loadingMessage = "Validating your OTP..."

        backendApi("getOtpValidate", reqData, headers, "post")
                .then(response => {
                        if (response && response.data) {
                                if (response.data.data.OTPAccepted === "Yes") {
                                        state.loadingState = false;
                                        saveProposal();
                                }else if(response.data.data.OTPAccepted === "No"){
                                        hideMessage.value = true;
                                        state.loadingState = false;
                                        state.errorOtpMessage = response.data.data.reason
                                        setTimeout(() => {
                                                state.errorOtpMessage = "";
                                                hideMessage.value = false; 
                                        }, 3000);
                                }
                        }
                })
                .catch(error => {
                        console.error(error);
                        // state.loadingState = false
                });

        state.otpNumbers.forEach(otp => {
                otp.value = "";
        });
        refs.value[0]?.focus();
};

const formatCurrentDate = (now) => {
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
        const microseconds = milliseconds + '000';
        const timezoneOffset = -now.getTimezoneOffset();
        const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
        const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
        const timezoneSign = timezoneOffset >= 0 ? '+' : '-';

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${microseconds}${timezoneSign}${offsetHours}:${offsetMinutes}`;
};

const saveProposal = () => {
        const expiryTime = JSON.parse(sessionStorage.getItem('expiryTime'));
        const reqData = {
                journey_id: state.journeyId,
                otpDetails: {
                        fields: {
                                otpReqCreatedTime: { input: formatCurrentDate(new Date()) },
                                otpReqUpdatedTime: { input: expiryTime },
                                otpReqOtpNo: { input: "" },
                                otpReqStatus: { input: "1" },
                                otpReqExpiryTimeInMin: { input: expiryTime }
                        }
                }
        };
        const headers = { "Authorization": `Bearer ${store.state.token}` };
        state.loadingState = true;
        state.loadingMessage = "Saving Proposal...."
        
        backendApi("saveProposal", reqData, headers, "post")
        .then(response => {
                if (response && response.data) {
                                state.loadingState = false;
                                proposalSubmission();
                        }
                })
                .catch(error => {
                        // state.loadingState = false;
                        console.error(error);
                });
};

const proposalSubmission = () => {
        const reqData = {
                journey_id: state.journeyId,
                redirect_url: new URL(location).origin + "/#/confirm-payment-diy"
        };
        const headers = { "Authorization": `Bearer ${store.state.token}` };
        state.loadingState = true;
        state.loadingMessage = "Submitting Proposal..."
        
        backendApi("saveProposal", reqData, headers)
        .then(response => {
                if(response && response.data.errors.length > 0){
                        emit('show-error-message', response.data)
                }
                if (response && response.data.data  && response.data?.errors.length ==0) {
                        state.loadingState = false;
                        paymentUrlPoll();
                }
        })
        .catch(error => {
                state.loadingState = false;
                console.error(error);
        });
};

const paymentUrlPoll = async () => {
        state.loadingState = true;
        state.loadingMessage = "Redirecting to payment gateway..."
        state.journeyId = JSON.parse(sessionStorage.getItem("journey_id"));
        try {
                const startTime = Date.now();
                const endTime = startTime + 3 * 60 * 1000;
                const stage = "Payment URL";
                const reqData = [state.journeyId, stage];
                const headers = {};

                while (Date.now() < endTime) {
                        const response = await backendApi("getPaymentUrlPoll", [], headers, "get", reqData);
                        if (response.data.errors.length > 0 || response.data.proposal_response?.errors[0].errorMessage) {
                                cancleOtpVerify();
                                state.loadingState = false;
                                emit('show-error-message', response.data.errors.length>0 ? response.data : response.data.proposal_response);
                                // break;
                        }
                        if (response.data.errors.length === 0) {
                                if (response.data.data.proposal_response  && response.data.data.proposal_response.errors && response.data.data.proposal_response.errors.length > 0) {
                                        emit('show-error-message', response.data.data.proposal_response);
                                        return;
                                }
                                if (!response.data.data.payment_url && response.data.data.proposal_response?.policy?.policyStatus==="Inspection Pending") {
                                        const inspectionLink = response?.data?.data?.proposal_response?.inspectionDetails.selfInspectionLink;
                                        const inspectionvalidTill = response?.data?.data?.proposal_response?.inspectionDetails.inspectionValidTill;
                                        if(inspectionLink){
                                                sessionStorage.setItem("inspectionLink", inspectionLink);
                                        }
                                        if(inspectionvalidTill){
                                                sessionStorage.setItem("inspectionvalidTill", inspectionvalidTill);
                                        }
                                        
                                        router.push('/inspection');
                                        return;
                                }
                                if (response.data.data.payment_url) {
                                        state.loadingState = false;
                                        cancleOtpVerify();
                                        window.location.href = response.data.data.payment_url;
                                        return;
                                }
                        }
                        await new Promise(resolve => setTimeout(resolve, 1000));
                }
                cancleOtpVerify();
                state.loadingState = false;
        } catch (error) {
                console.error("An error occurred during payment URL polling:", error);
                cancleOtpVerify();
                state.loadingState = false;
        }
};


const cancleOtpVerify = () => {
        emit('close-modal');
};

const disableVerify = computed(() => {
        return state.otpNumbers && state.otpNumbers.some(otp => String(otp.value).trim() === "");
});

// Watch for changes in `time` prop
watch(() => props.time, (newTime) => {
  localTime.value = newTime;
});
</script>


<style scoped lang="scss">
@import "../../assets/scss/components/select-card.scss";


</style>