<template lang="" >

  <div  >
    <div class="formWizard row mt-3 formmargin">
      <div class="col-md-6  " >
        <div class="position-sticky formobileview" >
          <div class="headingcards d-flex gap-3 justify-content-between align-items-center" > 
            <div>
              <h6 class="mb-0" v-if="motorProductType==='4W-Pvt'">Car Details</h6>
            <h6 class="mb-0" v-if="motorProductType==='2W-Pvt'">Bike Details</h6>
                <!-- <p class="mb-0" >{{this.state}} {{this.city}} {{this.rtoCode}}</p> -->
            <p class="mb-0" > 
              <span v-if="state.formData.rtoCode"><img src="../../assets/images/dot.svg" class="doticon" />{{state.formData.rtoCode}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span v-if="state.formData.carMake.makeDescription"><img src="../../assets/images/dot.svg" class="doticon" />{{state.formData.carMake.makeDescription}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span v-if="state.formData.model.modelDesc"><img src="../../assets/images/dot.svg" class="doticon" />{{state.formData.model.modelDesc}}&nbsp;&nbsp;&nbsp;&nbsp; </span> 
              <span v-if="state.formData.variant.variantDesc"><img src="../../assets/images/dot.svg" class="doticon" />{{state.formData.variant.variantDesc}}&nbsp;&nbsp;&nbsp;&nbsp; </span> 
              <span v-if="state.formData.fuelType.fuelType"><img src="../../assets/images/dot.svg" class="doticon" />{{state.formData.fuelType.fuelType.toUpperCase()}} </span> 
            </p>  
            </div>
            <div @click="openModalForVehicle()">
              <span class="editform" ><img src="../../assets/images/edit.svg"  />Edit</span>
            </div>
          </div>
        <ul
          class="nav nav-tabs m-3 d-block justify-content-left border-0"
          id="myTab"
          role="tablist"
        >
          <li
            v-for="(step, index) in state.steps"
            :key="index"
            class="nav-item"
            role="presentation"
            data-bs-placement="top"
            :title="step.title"
            :class="{ completed: isCompleted(index), 'isLastChild': index == state.steps.length-1 }"
          >
            <div class="d-flex justify-content-between">
              <a
              :class="[
                'd-flex',
                'align-items-center',
                'justify-content-between hovereffect',
                'gap-2',
                { active: state.activeTab === index },
              ]"
              @click="selectTab(index)"
              role="tab"
              :aria-controls="'step' + (index + 1)"
              :aria-selected="state.activeTab === index"
              style="width: fit-content"
            >
            <!-- v-html="step.subcontent -->
              <i class="activeicon">{{ step.icon }}</i>
              <div class="checkincon">
                <img src="../../assets/images/check.svg" />
                   
                  </div>
              <p class="mb-0" v-if="state.motorProductType==='4W-Pvt'" v-html="step.carsubcontent "></p>
              <p class="mb-0" v-if="state.motorProductType==='2W-Pvt'" v-html="step.bikesubcontent "></p>
            </a>
            
            </div>
          </li>
        </ul>
        </div>
      </div>

      <div class="tab-content   col-md-6" id="myTabContent">
        <div
          v-for="(step, index) in state.steps"
          :key="'content-' + index"
          :class="[
            'tab-pane',
            'fade',
            { show: state.activeTab === index, active: state.activeTab === index },
          ]"
          role="tabpanel"
          :id="'step' + (index + 1)"
          :aria-labelledby="'step' + (index + 1) + '-tab'"
        >
       
          <div>
            <CardLoader v-if="state.loadingState"/>
            <component v-else
              :is="step.SelectComponent"
              :data="step.alldata"
              :carplaceholder="step.carplaceholder"
              :bikeplaceholder="step.bikeplaceholder"
              :carexample="step.carexample"
              :bikeexample="step.bikeexample"
              :view="step.view"
              :showOdSection="state.showOdSection"
              :showTpSection="state.showTpSection"
              :showPreviousExpiry="state.showPreviousExpiry"
              :percentOptions="state.percentOptions"
              @item-clicked="handleItemClicked"
              :displayStepData="step.displayStepData"
              :selectedItem="step.selectedItem"
              :selectedItemvalue="step.selectedItemvalue"
              name="Single-Quote"
            />
           
          </div>
          <div
            class="d-flex mt-3 justify-content-between"
            :class="{
              'justify-content-between':
                index !== 0 && index !== state.steps.length - 1,
              'justify-content-end': index === 0,
              'justify-content-between': index === state.steps.length - 1,
            }"
          >  
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <editVehicleDetails></editVehicleDetails> -->
  <ModalVehicleEditing v-if="state.openModalForVehicleEditing" @closeModalEditing="closeModalEditing" @selectTabFromModal="selectTabFromModal"/>
</template>
<script>
import { computed, onMounted, reactive } from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import loading from "../pageLoaders/pageLoader.vue";
import SelectRTO from "../selectCard/vehicleDetails.vue";
import CardLoader from "../pageLoaders/cardLoader.vue";
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import backendApi from "@/apis/axios-backend";
// import editVehicleDetails from "@/modals/editVehicleDetails.vue";
import ModalVehicleEditing from "@/modals/ModalVehicleEditing.vue";



export default {
  name: "formWizard",
  props: {
    msg: String,
  },
  components:{
    SelectRTO,
    loading,
    CardLoader,
    // editVehicleDetails,
    ModalVehicleEditing
  }, 
  
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    let state = reactive({
      activeTab: 0,
      quoteCategory:'', 
      openModalForVehicleEditing: false,
      steps:[
    {
        title: "Step 1",
        icon: "1",
        carsubcontent: ' Choose <b>State</b>',
        bikesubcontent: ' Choose <b>State</b>',
        carplaceholder: 'Search State',
        bikeplaceholder: 'Search State',
        displayStepData: "state",
        carexample:"Maharashtra, Delhi" ,
        bikeexample:"Maharashtra, Delhi" ,
        SelectComponent: SelectRTO,
        searchData: "userData.state",
        alldata:[],
        iscompleted:false,
        view: "vehicleDetails",
        selectedItem:"state",
        selectedItemvalue:"",
        
      },
    {
        title: "Step 2",
        icon: "2",
        carsubcontent: ' Choose <b>City</b>',
        bikesubcontent: ' Choose <b>City</b>',
        carplaceholder: 'Search City',
        bikeplaceholder: 'Search City',
        displayStepData: "city",
        carexample: "Pune",
        bikeexample: "Pune",
        SelectComponent: SelectRTO,
        searchData: "userData.city",
        alldata:[],
        iscompleted:false,
        view: "vehicleDetails",
        selectedItem:"city",
        selectedItemvalue:"",


      },
      {
        title: "Step 3",
        icon: "3",
        carsubcontent: ' Choose  <b>RTO</b>',
        bikesubcontent: ' Choose  <b>RTO</b>',
        carplaceholder: 'Search RTO',
        bikeplaceholder: 'Search RTO',
        displayStepData: "rtoCode",
        carexample: "MH-12, MH-14",
        bikeexample: "MH-12, MH-14",
        SelectComponent: SelectRTO,
        searchData: "userData.rto",
        alldata:[],
        iscompleted:false,
        view: "vehicleDetails",
        selectedItem:"rtoCode",
        selectedItemvalue:"",

        

      },
      {
        title: "Step 4",
        icon: "4",
        carsubcontent: "Choose  <b> Car Make</b>",
        bikesubcontent: "Choose  <b> Bike Make</b>",
        carplaceholder: 'Search Car Make',
        bikeplaceholder: 'Search Bike Make',
        SelectComponent: SelectRTO,
        displayStepData: "carMake",
        carexample: "Hyundai",
        bikeexample: "Suzuki",
        alldata:[],
        iscompleted:false,
        view: "vehicleDetails",
        selectedItem:"carMake",
        selectedItemvalue:"",



      },
      {
        title: "Step 5",
        icon: "5",
        carsubcontent: "Choose <b>Car Model</b>",
        bikesubcontent: "Choose <b>Bike Model</b>",
        carplaceholder: 'Search Car Model',
        bikeplaceholder: 'Search Bike Model',
        SelectComponent: SelectRTO,
        displayStepData: "model",
        carexample: "Creta",
        bikeexample: "Access",
        alldata:[],
        iscompleted:false,
        view: "vehicleDetails",
        selectedItem:"model",
        selectedItemvalue:"",

      },
      {
        title: "Step 6",
        icon: "6",
        carsubcontent: "Choose <b>Car Variant</b>",
        bikesubcontent: "Choose <b>Bike Variant</b>",
        carplaceholder: 'Search Car Variant',
        bikeplaceholder: 'Search Bike Variant',
        SelectComponent: SelectRTO,
        displayStepData: "variant",
        carexample: "Prime Era T CRDI (1120 CC)",
        bikeexample: "Disk Break",
        alldata:[],
        iscompleted:false,
        view: "vehicleDetails",
        selectedItem:"variant",
        selectedItemvalue:"",

      },
      {
        title: "Step 7",
        icon: "7",
        carsubcontent: "Choose <b>Car Fuel Type</b>",
        bikesubcontent: "Choose <b>Bike Fuel Type</b>",
        SelectComponent: SelectRTO,
        carplaceholder: 'Select Car Fuel Type',
        bikeplaceholder: 'Select Bike Fuel Type',
        displayStepData: "fuelType",
        carexample: "petrol",
        bikeexample: "petrol",
        alldata:[],
        iscompleted:false,
        view: "vehicleDetails",
        selectedItem:"fuelType",
        selectedItemvalue:"",

      },
      {
        title: "Step 8",
        icon: "8",
        carsubcontent: "<b>Additional</b> Details",
        bikesubcontent: "<b>Additional</b> Details",
        SelectComponent: SelectRTO ,
        carplaceholder: 'Additonal Details',
        bikeplaceholder: 'Additonal Details',
        displayStepData: "additonalDetails",
        carexample: "",
        bikeexample: "",
        alldata:[],
        iscompleted:false,
        view: "additonalDetails",
        selectedItem:"",


      },
      {
        title: "Step 9",
        icon: "9",
        carsubcontent: "Claim",
        bikesubcontent: "Claim",
        SelectComponent: SelectRTO  ,
        iscompleted:false,
        displayStepData: "claim",
        carexample: "",
        bikeexample: "",
        alldata:[],
        view: "claim",
        selectedItem:"",
      },
      ],
      indexForActiveTab:"",
      motorProductType:'',
      allRtoDetails:[],
      userData:{},
      loadingMsg:"Please wait while we are fetching the data",
      loadingState:true,
      category:"rto",
      reqBody:{},
      itemClicked:"",
      vehicleKey:"",
      registrationYear:"",
      percentOptions:[],
      showOdSection: true,
      showTpSection: true,
      showPreviousExpiry: true,
      vehicleAge:null,
      searchfromhandlebutton:false,
      formData: {
        state:"",
        city:"",
        rtoCode:"",
        makeCode:"",
        model:"",
        variant: "",
        fuelType:"",
        vehicleType:"",
        carMake:''

      },
      formAlldata:[{ state: [] },
    { city: [] },
    { rtoCode: [] },
    { carMake: [] },
    { model: [] },
    { variant: [] },
    { fuelType: [] },
    { makeCode: [] }],

      formDataIndex : [{ state: "" },
    { city: "" },
    { rtoCode: "" },
    { carMake: '' },
    { model: "" },
    { variant: "" },
    { fuelType: "" },
    { makeCode: "" }
   ]
    })
    onMounted(()=>{
      state.quoteCategory = store.state.quoteCategory;
      if(sessionStorage.getItem("rtoCode")){
        const rtoCode=JSON.parse(sessionStorage.getItem("rtoCode"));
        if(rtoCode){
          // const rtoCode=JSON.parse(sessionStorage.getItem("rtoCode"));
          state.formData["rtoCode"] =rtoCode;
          state.itemClicked="rtoCode"
        state.reqBody= {
          category: "rto",
          rtoCode:rtoCode ,
        }
      backendApi("vehicleSearch", [],{"Authorization":`Bearer ${ store.state.token}`}, "get", {}, state.reqBody)
        .then((response) => {
          if(response.data.errors.length > 0) {
            const msg = [];
            if(response.data.errors[0].errorDisplayMsg !=""){
            msg.push(response.data.errors[0].errorDisplayMsg);
            }
            
          }  else if(response && response.data) {  
         for(let i=0;i<4;i++){
         state.steps[i].iscompleted = true; 
       }   
       selectTab(3);
     const statelist=[];
     const city=[];
     const rto=[];
     statelist.push(response.data.data.rto[0].state);
     city.push(response.data.data.rto[0].city);
     rto.push(rtoCode);
     state.formData["state"] =response.data.data.rto[0].state;
     state.formData["city"] =response.data.data.rto[0].city;
     state.steps[0].selectedItemvalue=response.data.data.rto[0].state;
     state.steps[1].selectedItemvalue=response.data.data.rto[0].city;
     state.steps[2].selectedItemvalue=rtoCode;
      state.steps[0].alldata=statelist;
     state.steps[1].alldata=city;
     state.steps[2].alldata=rto;
     state.formAlldata[0]["state"]=statelist;
     state.formAlldata[1]["city"]=city;
     state.formAlldata[2]["rtoCode"]=rto;
     state.formDataIndex[0].state=response.data.data.rto[0].state;
     state.formDataIndex[1].city=response.data.data.rto[0].city;
     state.formDataIndex[2].rtoCode=rtoCode;
     state.userData = JSON.parse(sessionStorage.getItem("userData"));
     state.userData["state"] =response.data.data.rto[0].state;
     state.userData["city"] =response.data.data.rto[0].city;
     state.userData['pinCode']=response.data.data.rto[0].zipCode;
     state.userData["rtoCode"] =rtoCode;
     sessionStorage.setItem("userData",JSON.stringify(state.userData));
     
     }
     })
     .catch((error) => {
            const msg = "Service is Unavailable, Please try again later.";
            router.push({ path: '/error-page', query: { backUrl:'New-Journey', msg: msg } });
        });
    
        }
      }
  //  const pollingSingle =  sessionStorage.getItem("singleQuotePollIntervalID")
   
      if(sessionStorage.getItem("userData")){
        
        const parsedData=JSON.parse(sessionStorage.getItem("userData"));
        
        if (parsedData.RegDate) {
          for (let i = 0; i<9; i++) {
        state.steps[i].iscompleted = true;  
      }
          state.percentOptions=parsedData.percentOptions
            selectTab(8);
        }
      }
       //get index for active tab
      // state.indexForActiveTab= route.query.index;
      state.indexForActiveTab= JSON.parse(sessionStorage.getItem("edit"));
      sessionStorage.removeItem('edit')
      if(state.indexForActiveTab){
        for (let i = state.indexForActiveTab ; i >=0; i--) {
        state.steps[i].iscompleted = true;  
      }
      var indexint = parseInt(state.indexForActiveTab, 10);
      
      selectTab(indexint);
      }
      //getting alldata from session
      if(sessionStorage.getItem("formAlldata")){
      state.formAlldata=JSON.parse(sessionStorage.getItem("formAlldata"))
      state.steps[0].alldata=state.formAlldata[0]["state"];
      state.steps[1].alldata=state.formAlldata[1]["city"];
      state.steps[2].alldata=state.formAlldata[2]["rtoCode"];
      state.steps[3].alldata=state.formAlldata[3]["carMake"];
      state.steps[4].alldata=state.formAlldata[4]["model"];
      state.steps[5].alldata=state.formAlldata[5]["variant"];
      state.steps[6].alldata=state.formAlldata[6]["fuelType"];}
      
      if(sessionStorage.getItem("userSelection")){
        state.formDataIndex=JSON.parse(sessionStorage.getItem("userSelection"));
        const seletditem=  JSON.parse(sessionStorage.getItem("userSelection"))
        state.steps[0].selectedItemvalue=seletditem[0].state;
        state.steps[1].selectedItemvalue=seletditem[1].city;
        state.steps[2].selectedItemvalue=seletditem[2].rtoCode;
        state.steps[3].selectedItemvalue=seletditem[3]["carMake"];
        console.log("--sec value",seletditem[3]["carMake"]);
        state.steps[4].selectedItemvalue=seletditem[4]["model"];
        state.steps[5].selectedItemvalue=seletditem[5]["variant"];
        state.steps[6].selectedItemvalue=seletditem[6]["fuelType"];
        sessionStorage.removeItem("userSelection");
        if(state.indexForActiveTab>3){
          state.category='vehicle';
        }
         //adding itemclicked
         if(state.indexForActiveTab>0){
          state.itemClicked=state.steps[state.indexForActiveTab-1].displayStepData;
         }
        
        const pickedValues = state.formDataIndex.slice(0,state.indexForActiveTab);
        pickedValues.forEach(obj => {
      const key = Object.keys(obj)[0]; // Extract the key from the object
      if (key in state.formData) { // Check if the key exists in formData
        state.formData[key] = obj[key]; // Update the formData with the value
      }
    });
      }
      state.motorProductType=store.state.motorProductType;
      if(sessionStorage.getItem("userData")){
      state.userData = JSON.parse(sessionStorage.getItem("userData"));
      }
      state.category="rto"
      state.loadingState=true;   

      searchVehicle()
    })
    
    const currentYearCalculated = computed(()=>{
      return new Date().getFullYear();
    })
    // const vehicleAge = computed(()=>{
    //   return this.currentYear - this.registrationYear;
    // })
    
    function completed(index) {
      state.activeTab = index;
    }
   function handleItemClicked(item){
     state.searchfromhandlebutton=true;
     state.itemClicked = item.key;
     state.registrationYear= item.selectedYear;
     const {key,value,selectedDate} = item;
      
     if(key==="state"){
       state.formData= {
       state:"",
       city:"",
       rtoCode:"",
       makeCode:"",
       model:"",
       variant: "",
       fuelType:"",
       vehicleType:"",
       carMake:''
     },
     state.category="rto";
     }
     state.formData[key] = value;
     for(var i=0;i<state.steps.length-1;i++){{
      if(state.steps[i].selectedItem==key){
        state.steps[i].selectedItemvalue=value;
        console.log("--first value",value);
      }
     }

     }
     for (let obj of state.formDataIndex) {
    if (key in obj) {
      obj[key] = value; // Update the value
      break; // Exit the loop once the key is found and updated
    }
  }
  sessionStorage.setItem("userSelection",JSON.stringify(state.formDataIndex));

     state.userData = JSON.parse(sessionStorage.getItem("userData"));
     state.userData[key]=value;
     sessionStorage.setItem("userData",JSON.stringify(state.userData));
     var index= state.steps.findIndex(step => step.displayStepData == key);
     if(key=="additonalDetails" && selectedDate==''){
       state.steps[index]['iscompleted']=false;
     }
     else{
       state.steps[index]['iscompleted']=true;
     }
     
     state.loading = true;
     if(state.itemClicked == "additonalDetails"){
       state.showOdSection= displayODSection()
       state.showTpSection= displayTPSection()
       state.showPreviousExpiry= isPreviousExpiryDate()
       state.percentOptions = getNCBGridValue()

       state.loading=false;
       if( state.steps[index]['iscompleted']){
         nextStep();
       }
       
     }
     else
     searchVehicle();
    }

    function checkBHSeries() {
      const fullCurrentYear = new Date().getFullYear();
      const bhSeriesPattern = /^(\d{2})([A-Z]{2})(\d{4})([A-Z]{1,2})$/;
      if(state.userData.vehicleNumber){
        const match = state.userData.vehicleNumber.match(bhSeriesPattern);
        if (!match) {
          return { valid: false, regYear: null };
        }
        const regYearLastTwo = parseInt(match[1], 10);
        const regYearFull = 2000 + regYearLastTwo;
        if (regYearFull < 2021 || regYearFull > fullCurrentYear) {
          return { valid: false, regYear: regYearFull };
        }
        return { valid: true, regYear: regYearFull };
      }
    }
    function searchVehicle(category="rto"){
      state.loadingState=true;
        state.category = state.formData.rtoCode != '' ? 'vehicle' : category;
        state.reqBody= {
          category: state.category,
          state: state.category === 'vehicle' ? '' : state.formData.state,
          city: state.category === 'vehicle' ? '' : state.formData.city,
          rtoCode: state.category === 'vehicle' ? '' : state.formData.rtoCode,
          vehicleType: state.category === 'vehicle' ? (state.motorProductType === '4W-Pvt' ? '1' : '2'):'',
          make: state.category === 'vehicle' ? (state.formData?.carMake?.makeCode || '') : '',
          model: state.category === 'vehicle' ? (state.formData?.model?.modelCode || '') : '',
          variant: state.category === 'vehicle' ? (state.formData?.variant?.variantCode || '') : '',
          fuelType: state.category === 'vehicle' ? (state.formData?.fuelType.fuelType || '') : '',

        }

      backendApi("vehicleSearch", [],{"Authorization":`Bearer ${ store.state.token}`}, "get", {}, state.reqBody)
        .then((response) => {
          if(response.data.errors.length > 0) {
            state.loadingState=false;
            const msg = [];
            if(response.data.errors[0].errorDisplayMsg !=""){
            msg.push(response.data.errors[0].errorDisplayMsg);
            }
          } else if(response && response.data) {
            if(state.userData.vehicleNumber && checkBHSeries().valid && state.itemClicked == ""){
              sessionStorage.setItem("all-rto", JSON.stringify(response.data.data.rto))
            }
            if(state.quoteCategory=="New" && state.itemClicked ==""){
              sessionStorage.setItem("all-rto", JSON.stringify(response.data.data.rto))
            }
            state.loadingState=false;
          if(state.steps[0].displayStepData == "state" && state.formData.state =="" && state.formData.rtoCode ==""){
            response?.data?.data?.rto.forEach(rto => {
            state.allRtoDetails.push(rto);
            
          });
          state.allRtoDetails = state.allRtoDetails.reduce((acc, current) => {
            if (!acc.find(item => item.state === current.state)) {
              acc.push(current);
            }
            return acc;
          }, []).map(item => item.state);
          state.steps[0].alldata=state.allRtoDetails;
          state.formAlldata[0]["state"]=state.allRtoDetails;
          }
          
          if(state.steps[1].displayStepData == "city" && state.formData.state !="" && state.formData.city =="" ){
          state.allRtoDetails = [];
          nextStep();
          
          
          
          response.data.data.rto.forEach(city => {
            state.allRtoDetails.push(city.city);
            
          });
          state.allRtoDetails = state.allRtoDetails.reduce((acc, current) => {
            if (!acc.find(item => item === current)) {
              acc.push(current);
            }
            return acc;
          }, []).map(item => item);
          state.steps[1].alldata=state.allRtoDetails;
          state.formAlldata[1]["city"]=state.allRtoDetails;
          } 
          if(state.steps[2].displayStepData == "rtoCode" &&  state.formData.state !="" && state.formData.city !="" && state.formData.rtoCode==""){
          state.allRtoDetails = [];
          if(state.searchfromhandlebutton){
            nextStep();
          }
          
          response.data.data.cityrto.forEach(cityrto => {
            state.allRtoDetails.push(cityrto.rtoCode);
          });
           state.steps[2].alldata=state.allRtoDetails;
           state.formAlldata[2]["rtoCode"]=state.allRtoDetails;
          state.allRtoDetails = state.allRtoDetails.reduce((acc, current) => {
            if (!acc.find(item => item.rtoCode === current.rtoCode)) {
              acc.push(current);
            }
            return acc;
          }, []).map(item => item.rtoCode);
          } 
          if(state.category == "vehicle" && state.itemClicked =="rtoCode"){
            state.allRtoDetails = [];
           
            if(state.searchfromhandlebutton){
            nextStep();
          }
          
            response.data.data.make.forEach(make => {
            state.allRtoDetails.push(make);
            state.steps[3].alldata=state.allRtoDetails;
            state.formAlldata[3]["carMake"]=state.allRtoDetails;
          });
          state.allRtoDetails = state.allRtoDetails.reduce((acc, current) => {
            if (!acc.find(item => item.rtoCode === current.rtoCode)) {
              acc.push(current);
            }
            return acc;
          }, []).map(item => item.rtoCode); 
          } 
          if(state.category == "vehicle" && state.itemClicked =="carMake"){
            state.allRtoDetails = [];
            
            if(state.searchfromhandlebutton){
            nextStep();
          }
          
            response.data.data.model.forEach(model => {
              state.allRtoDetails.push(model);
              state.steps[4].alldata=state.allRtoDetails;
              state.formAlldata[4]["model"]=state.allRtoDetails;
          });
          state.allRtoDetails = state.allRtoDetails.reduce((acc, current) => {
            if (!acc.find(item => item.rtoCode === current.rtoCode)) {
              acc.push(current);
            }
            return acc;
          }, []).map(item => item.rtoCode); 
          } 
          if(state.category == "vehicle" && state.itemClicked =="model"){
            state.allRtoDetails = [];
           
            if(state.searchfromhandlebutton){
            nextStep();
          }
          
            response.data.data.variant.forEach(variant => {
              state.allRtoDetails.push(variant);
              state.steps[5].alldata=state.allRtoDetails;
              state.formAlldata[5]["variant"]=state.allRtoDetails;
          });
          state.allRtoDetails = state.allRtoDetails.reduce((acc, current) => {
            if (!acc.find(item => item.rtoCode === current.rtoCode)) {
              acc.push(current);
            }
            return acc;
          }, []).map(item => item.rtoCode); 
          } 
          if(state.category == "vehicle" && state.itemClicked =="variant"){
            state.allRtoDetails = [];
           
            if(state.searchfromhandlebutton){
            nextStep();
          }
          
            response.data.data.fuelType.forEach(fuelType => {
              state.allRtoDetails.push(fuelType);
              state.steps[6].alldata=state.allRtoDetails;
              state.formAlldata[6]["fuelType"]=state.allRtoDetails;
              if(state.allRtoDetails.length===1){
                state.formData["fuelType"] =state.allRtoDetails[0];
                state.steps[6].selectedItemvalue=state.allRtoDetails[0];
                state.formDataIndex[6].fuelType=state.allRtoDetails[0]["fuelType"];
                state.userData = JSON.parse(sessionStorage.getItem("userData"));
                state.userData["fuelType"]=state.allRtoDetails[0];
                sessionStorage.setItem("userData",JSON.stringify(state.userData));
                state.steps[6].iscompleted = true;
                state.itemClicked ="fuelType";
                // nextStep();
                searchVehicle();
                       
              }
              sessionStorage.setItem("formAlldata",JSON.stringify(state.formAlldata));
          });
          state.allRtoDetails = state.allRtoDetails.reduce((acc, current) => {
            if (!acc.find(item => item === current)) {
              acc.push(current);
            }
            return acc;
          }, []).map(item => item); 
          } 
          if(state.category == "vehicle" && state.itemClicked =="fuelType"){
            if(response.data.data.vehicleKey){
              nextStep();
            state.vehicleKey = response.data.data.vehicleKey[0].vehicleKey;
            state.userData = JSON.parse(sessionStorage.getItem("userData"));
            state.userData["vehicleKey"]=state.vehicleKey;
            sessionStorage.setItem("userData",JSON.stringify(state.userData));
            }
            

          } 
        }
        })
        .catch((error) => {
            const msg = "Service is Unavailable, Please try again later.";
            router.push({ path: '/error-page', query: { backUrl:'New-Journey', msg: msg } });
            state.loadingState=false;
        });
    }
    function isCompleted(index) {
      return index < state.activeTab;
    }
   function displayODSection() {
    state.vehicleAge = currentYearCalculated.value - state.registrationYear;
      if (state.motorProductType === '2W-Pvt') {
        return state.vehicleAge <= 5.10 || state.vehicleAge > 5.10;
      } else if (state.motorProductType === '4W-Pvt') {
        return state.vehicleAge <= 3.10 || state.vehicleAge > 3.10;
      }
      return false;
    }
    function displayTPSection() {
      state.vehicleAge = currentYearCalculated.value - state.registrationYear;

      if (state.motorProductType === '2W-Pvt') {
        return state.vehicleAge <= 5.10;
      } else if (state.motorProductType === '4W-Pvt') {
        return state.vehicleAge <= 3.10;
      }
      return false;
    }
    function isPreviousExpiryDate() {
      state.vehicleAge = currentYearCalculated.value - state.registrationYear;

      if (state.motorProductType === '2W-Pvt') {
        return state.vehicleAge > 5.10;
      } else if (state.motorProductType === '4W-Pvt') {
        return state.vehicleAge > 3.10;
      }
      return false;
    }
   
    function getNCBGridValue() {
      state.vehicleAge = currentYearCalculated.value - state.registrationYear;
      const age = state.vehicleAge;
      const ncbPercentage = {
        0:["0%"],
        1:["0%"],
        2:["0%", "20%"],
        3:["0%", "20%", "25%"],
        4:["0%", "20%"," 25%", "35%"],
        5:["0%", "20%","25%", "35%", "45%"],
        6:["0%", "20%","25%", "35%", "45%", "50%"]

      }
      // Use 6 as a key for age > 5
      const applicableAge = Math.min(age, 6); 
      return ncbPercentage[applicableAge] || 'Unknown';
    }
    
    const nextStep = () => {
      if (state.activeTab < state.steps.length - 1) {
        
        state.activeTab += 1;
      }
    };

    const previousStep = () => {
      if (state.activeTab > 0) {
        state.activeTab -= 1;
      }
    };

    const selectTab = (index) => {
      console.log("index------------",index)
      if (state.steps[index]['iscompleted']) {
        state.activeTab = index;
      }
      for (let i = state.activeTab+1 ; i < state.steps.length; i++) {
        state.steps[i].iscompleted = false;  
        state.steps[i].selectedItemvalue="";
      }
      const fields = [
        'state',
        'city',
        'rtoCode',
        'carMake',
        'model',
        'variant',
        'fuelType',
        'vehicleType',
        'makeCode',
        
    ];
    for (let i = index; i < fields.length; i++) {
        state.formData[fields[i]] = '';
    }

    const userData = JSON.parse(sessionStorage.getItem('userData'));
    let trigger = index==0 ? 'state' :  index==1 ? 'city' : index==2 ? 'rto' : index==3 ? 'carMake' : index==4 ? 'carModel' : index==5 ? 'carVariant' : 'others'
    switch (trigger) {
      case 'state':
      case 'city':
        delete userData.rtoCode;
          delete userData.carMake;
          delete userData.model;
          delete userData.variant;
          delete userData.fuelType;
          delete userData.RegDate;
          delete userData.MfgYear;
          delete userData.OdDate;
          delete userData.TpDate;
          break;
      case 'rto':
        delete userData.carMake;
        delete userData.model;
        delete userData.variant;
        delete userData.fuelType;
        delete userData.RegDate
        delete userData.MfgYear
        delete userData.OdDate
        delete userData.TpDate
        break;
        case 'carMake':
          delete userData.model;
          delete userData.variant;
          delete userData.fuelType;
          delete userData.RegDate
          delete userData.MfgYear
          delete userData.OdDate
          delete userData.TpDate
          break;
        case 'carModel':
          delete userData.variant;
          delete userData.fuelType;
          delete userData.RegDate
          delete userData.MfgYear
          delete userData.OdDate
          delete userData.TpDate
          break;
          case 'carVariant':
          delete userData.fuelType;
          delete userData.RegDate
          delete userData.MfgYear
          delete userData.OdDate
          delete userData.TpDate
          break;
          case 'others':
          // delete userData.rtoCode;
          // delete userData.carMake;
          // delete userData.model;
          // delete userData.variant;
          // delete userData.fuelType;
          // delete userData.RegDate
          // delete userData.MfgYear
          // delete userData.OdDate
          // delete userData.TpDate
          break;
        default:
          break;
      }
    sessionStorage.setItem('userData', JSON.stringify(userData));
    };
    function openModalForVehicle(){
      state.openModalForVehicleEditing = true;
    }
    function closeModalEditing(){
      state.openModalForVehicleEditing = false;
    }
    function selectTabFromModal(index){
      selectTab(index)
    }
    return {
      state,
      completed,
      searchVehicle,
      isCompleted,
      nextStep,
      previousStep,
      selectTab,
      displayODSection,
      displayTPSection,
      isPreviousExpiryDate,
      getNCBGridValue,
      currentYearCalculated,
      handleItemClicked,
      openModalForVehicle,
      closeModalEditing,
      selectTabFromModal
    };
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/form-wizard";
.editform{
  cursor: pointer;
}
.dark{
  .editform img{
    filter: invert(1) !important;
  }
  .commonicon{
    filter: invert(1) !important;
  }
}
</style>
